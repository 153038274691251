import {useEffect, useState} from 'react';
import EventListener from '../shared/helpers/EventListener';
import {useNavigate} from 'react-router-dom';
import routes from '../routes';
import {Typography} from "../shadcn/components/ui/Typography";

const ErrorHanlder = () => {
    const [err, setErr] = useState(false);
    const navigate =useNavigate();
    useEffect(() => {
        const handler = EventListener.addListener('errorHandling', setErr);
        return () => {
            handler.removeEventListener();
        };
    }, []);
    if (!err) return null;
    return (
        <div
            onClick={() => {
                setErr(false);
                navigate(routes.LOGIN);
            }}
        >
            <Typography
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    color: 'red',
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    zIndex: 10000,
                }}
            >
                Qualcosa e' andato storto
            </Typography>
        </div>
    );
};

export default ErrorHanlder;
