import produce from 'immer';
import { useEffect, useMemo, useReducer } from 'react';
import { UserType } from '../shared/types/types';
import {
  AuthContext,
  AuthContextState,
  initialState,
} from './AuthContext';
import AuthApi from '../pages/auth/AuthApi/LoginService';
import ToastService from '../services/ToastService';
import SessionService from 'src/services/SessionService';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOG_OUT = 'LOG_OUT';

export type SetUserAction = {
  type: typeof SET_USER;
  payload: UserType;
};

export type SetLogOutAction = {
  type: typeof LOG_OUT;
};

type State = {} & AuthContextState;

type Action = SetUserAction | SetLogOutAction;

const userContextReducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case SET_USER:
      draft.user = action.payload;
      break;

    case LOG_OUT:
      draft.user = null;
  }
});

type Props = {
  children: React.ReactNode;
};

const Authentication = ({ children }: Props) => {
  const [{ user }, dispatch] = useReducer(userContextReducer, initialState);

  const setUser = (payload: UserType) => {
    dispatch({
      type: SET_USER,
      payload: payload,
    });
  };

  const setLogout = () => {
    dispatch({ type: LOG_OUT });
  };

  useEffect(() => {
    const asyncFn = async () => {
      try {
        const { user } = await AuthApi.me();
        user && setUser(user);
      } catch (e) {
        ToastService.error(e);
      }
    };
    asyncFn();
    SessionService.onSetLogout(setLogout);
    SessionService.onSetUser(setUser);
  }, []);

  const contextValue: AuthContextState = useMemo(
    () => ({
      user,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default Authentication;
