import React, {useContext, useState} from 'react';

import i18n from 'src/i18n';
import {AuthContext} from 'src/authentication/AuthContext';
import {userIsAdminOrTrainer} from 'src/shared/constants/constant';

import BookingExtraOptions from './BookingExtraOptions';
import {FormType, NavigationActionsType} from '../BookingPage';
import Hour from './Hour';

type Props = {
  navigationActions: NavigationActionsType;
} & FormType;

function Availabilities({navigationActions, form}: Props) {
  const {user} = useContext(AuthContext);
  const [isClicked, setIsClicked] = useState({id: 0, isOpen: false});
  const {slots, eventId, weeklyAvailabilityId} = form.watch()

  return (
    <>
      {slots.length === 0 ? (
        <div>{i18n.t('availabilities.noHourAvailable')}</div>
      ) : (
        <div className="grid col-1 gap-6 w-full">
          {user && userIsAdminOrTrainer(user.role_id) && <BookingExtraOptions form={form}/>}
          {/* NOTE here we select just first availabilities, in future we might use more than one and we will need to change below line */}
          {slots.map((hour, i) => {
            return (
              <Hour
                form={form}
                key={i + 2}
                setIsClicked={setIsClicked}
                navigationActions={navigationActions}
                isClicked={isClicked}
                id={i}
                hour={hour}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default Availabilities;
