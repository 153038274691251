import HttpService from 'src/services/HttpService';

class AuthApi {
  public login(body: { email: string; password: any }): Promise<any> {
    const {email, password} = body;
    return HttpService.post(`/users/sign_in`, {
      user: {
        email,
        password,
      },
    });
  }

  public logout(): Promise<any> {
    return HttpService.delete(`/users/sign_out`);
  }

  public me(): Promise<any> {
    return HttpService.get(`/users/me`);
  }

  public get_reset_password_email(email: string): Promise<any> {
    return HttpService.post(`/users/password`, {
      user: {
        email,
      },
    });
  }

  public resetPassword(body: {
    resetPasswordToken: string | null;
    password: string;
    passwordConfirmation: string;
  }): Promise<any> {
    const {resetPasswordToken, password, passwordConfirmation} = body;
    return HttpService.put(`/users/password`, {
      user: {
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    });
  }
}

export default new AuthApi();
