import React from 'react';
import appRoutes from '../../routes';
import CheckoutForm from './CheckoutForm';
import './payment.css';
import PaymentSuccessPage from './PaymentSuccessPage';
import StripeElementProvider from 'src/pages/payment/StripeElementProvider';
import {t} from 'i18next';
import {Typography} from "../../shadcn/components/ui/Typography";


export const paymentRoutes = [
  {
    path: appRoutes.PAYMENT_SUCCESS,
    element: (
      <StripeElementProvider>
        <PaymentSuccessPage/>
      </StripeElementProvider>
    ),
  },
  {
    index: true,
    element: (
      <StripeElementProvider>
        <CheckoutForm/>
      </StripeElementProvider>
    ),
  },
];
