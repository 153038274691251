import { t } from 'i18next';
import { DateTime } from 'luxon';

export const toWeekDayLong = (date: string) => {
  return t(`days.${DateTime.fromISO(date).weekdayLong}`);
};

const formatHourMin: {hour: "2-digit", minute: "2-digit"} = {
  hour: "2-digit",
  minute: "2-digit"
};

const formatYearMonthDay: { year: "numeric", month: "short", day: "2-digit" } = {
  year: 'numeric',
  month: 'short',
  day: '2-digit'
}

export const toHourMinute = (dat: string) => {
  let serverTime = DateTime.fromISO(dat)
  return serverTime.toLocaleString(formatHourMin);
};

export const toDateShort = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
};

export const toDateTimeShort = (date: string) => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
}

export const prettyFormat = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(formatYearMonthDay);
};

export const isBeforeToday = (date: string) => {
  return DateTime.fromISO(date) < DateTime.now().startOf('day');
};

export const compareDates = (date1: string, date2: Date) => {
  const d1 = DateTime.fromISO(date1);
  const d2 = DateTime.fromJSDate(date2);
  return d1.hasSame(d2, 'day');
};

export const atEndOfDay = (date: DateTime) => {
  return date.endOf('day').toISO();
};

export const localTimeZoneName = () => {
  return DateTime.local().zoneName;
};

export const atBeginOfDay = (date: DateTime) => {
  return date.startOf('day').toISO();
};

export const isValidDate = (date: string) => {
  return DateTime.fromISO(date).isValid;
};

export const fromJSDateToISO = (date: Date) => {
    return DateTime.fromJSDate(date).toISO();
}
export const centToEuro = (cents: number | string) => Number(cents) / 100;
export const euroToCent = (euro: number | string) => Number(euro) * 100;


export function convertMinutesToHoursMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = mins.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}