import {useNavigate, useParams} from "react-router-dom";
import * as yup from 'yup';
import {
  AvailabilityOverrideType,
  EventType,
  FormExtraProps,
  hourToPayload,
  HourType,
} from "../../../shared/types/types";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import React, {useEffect} from "react";
import LoadingService from "../../../components/loading/LoadingService";
import Api from "../../../shared/api/Api";
import ToastService from "../../../services/ToastService";
import {DateTime} from "luxon";
import {availOverridesSchema} from "./schemas";
import {useHours} from "./formUtils";
import {atEndOfDay} from "../../../shared/helpers/utils";

const availOverrideToForm = (availOverride: AvailabilityOverrideType) => {
  // we remove start_date and end_date from the object and we add a date object
  const { start_date, end_date, ...rest } = availOverride;
  return {
    ...rest,
    date: {
      from: DateTime.fromISO(availOverride.start_date).toJSDate(),
      to: DateTime.fromISO(availOverride.end_date).toJSDate(),
    },
    createdLocally: false,
    touched: false
  }
}

type AvailOverrideDecoratedType = {
  id: number;
  date?: {
    from: Date;
    to?: Date;
  };
  weekly_availability_id: number;
}& FormExtraProps

export type AvailOverrideFormType = {
  currentAvailOverrides?: AvailOverrideDecoratedType
  availabilityOverrides: AvailOverrideDecoratedType[]
  events: EventType[];
  hours: (HourType & FormExtraProps)[];
}

const availOverrideToPayload = (availOverride: AvailOverrideDecoratedType) => {
  const {date, ...rest} = availOverride;
  // when user select only start day, then end day is the same as start day
  if(!date) return
  const end_date = atEndOfDay(DateTime.fromJSDate(date.to || date.from))
  return {
    ...rest,
    start_date: DateTime.fromJSDate(date.from).toISO(),
    end_date: end_date
  }
}

export function useAvailabilityOverride() {
  const {id: weeklyAvailabilityId} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const form = useForm<AvailOverrideFormType>({
    resolver: yupResolver(availOverridesSchema),
    mode: 'onSubmit',
    defaultValues: {
      events: [],
      availabilityOverrides: [],
      hours: [],
    }
  });

  // @ts-ignore
  const {appendNewHour, removeHour} = useHours(form)

  const defaultAvailOverride = {
    id: Math.random(),
    date: {
      from: DateTime.now().startOf('day').toJSDate(),
      to: DateTime.now().endOf('day').toJSDate(),
    },
    weekly_availability_id: Number(weeklyAvailabilityId),
    createdLocally: true,
    touched: true
  }

  const findAvailOverride = (id: number) => form.getValues('availabilityOverrides').find(availOverride => availOverride.id == id)

  // FETCH EVENTS
  useEffect(() => {
    const controller = new AbortController();
    const fetchEvents = async () => {
      try {
        LoadingService.show();
        const response = await Api.getResources<{data: EventType[]}>('events')
        form.setValue('events', response.data);
        return response
      } catch (error) {
        ToastService.error(error);
      } finally {
        LoadingService.hide();
      }
    }
    fetchEvents()

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(function fetchAvailAndHours() {
    if (!weeklyAvailabilityId) return
    const controller = new AbortController();
    updateFormValues()

    return () => {
      controller.abort();
    };
  }, [weeklyAvailabilityId])

  const removeAvailOverride = async (id: number) => {
    try {
      LoadingService.show();
      const availOverride = findAvailOverride(id)
      if (!availOverride) {
        ToastService.error("Availability Override not found");
        return
      }
      await Api.deleteResource('availability_overrides', availOverride.id);
      // todo delete all hours in api
      const availOverrides = await Api.getResources<AvailabilityOverrideType[]>('availability_overrides', `weekly_availability_id=${weeklyAvailabilityId}`)
      form.setValue("availabilityOverrides", availOverrides.map((availOverride) => availOverrideToForm(availOverride)))
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };
  const onSubmit = async (data: AvailOverrideFormType, onSuccess: () => void) => {
    try {
      LoadingService.show();
      const availOverrideResponse = await handleAvailabilityOverrides(data);
      await handleHours(data, availOverrideResponse);
      await updateFormValues();
      onSuccess();
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  }

  const handleAvailabilityOverrides = async (data: AvailOverrideFormType): Promise<AvailabilityOverrideType | null> => {
    if (data.currentAvailOverrides && data.currentAvailOverrides.touched) {
      const payload = availOverrideToPayload(data.currentAvailOverrides);
      if (data.currentAvailOverrides.createdLocally) {
        return await Api.createResource('availability_overrides', payload);
      } else {
        return await Api.editResource('availability_overrides', data.currentAvailOverrides.id, payload);
      }
    }
    return Promise.resolve(null);
  }

  const handleHours = async (data: AvailOverrideFormType, availOverrideResponse: AvailabilityOverrideType | null) => {
    const arrayPromises = (data.hours || []).filter(h => h.touched).map(h => {
      // if we have a new availability override we need to update the availability_override_id of the hour
      h.availability_override_id = availOverrideResponse?.id || h.availability_override_id;
      const payload = {...hourToPayload(h), weekly_availability_id: Number(weeklyAvailabilityId)};
      return h.createdLocally ? Api.createResource('hours', payload) : Api.editResource('hours', h.id, payload);
    });
    await Promise.all(arrayPromises);
  }

  const updateFormValues = async () => {
    const availOverrides = await Api.getResources<AvailabilityOverrideType[]>('availability_overrides', `weekly_availability_id=${weeklyAvailabilityId}`);
    const hours = await Api.getResources<HourType[]>('hours', `weekly_availability_id=${weeklyAvailabilityId}&with_availability_overrides=true`);
    form.setValue("availabilityOverrides", availOverrides.map(availOverrideToForm));
    form.setValue("hours", hours);
  }
  return {form, navigate, onSubmit, defaultAvailOverride, appendNewHour, removeHour, removeAvailOverride}
}
