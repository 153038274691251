import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import { GENERAL_FONT } from './shared/constants/constant';
import Loading from './components/loading/Loading';
import './index.css';
import {ThemeProvider} from "./pages/booking/components/ThemeProvider";

const AppWrapper = (props: any) => {
  return (
    <div
      className={`relative min-h-screen ${GENERAL_FONT} bg-background flex flex-col max-w-full`}
    >
      {props.children}
      <Loading />
    </div>
  );
};

export const router = createBrowserRouter([
    // match everything with "*"
    { path: "*", element: <App /> }
])

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="teo-time-theme">
      <AppWrapper>
          <RouterProvider router={router}/>
      </AppWrapper>
    </ThemeProvider>
    <ToastContainer
      autoClose={1000}
      limit={1}
      hideProgressBar={true}
      closeOnClick
      pauseOnFocusLoss
      draggable={false}
      newestOnTop
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
