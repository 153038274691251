import _ from "lodash";
import React, {useEffect, useState} from 'react';
import {Check, ChevronsUpDown} from "lucide-react";
import LoadingService from 'src/components/loading/LoadingService';

import Api from 'src/shared/api/Api';
import {UserType} from 'src/shared/types/types';
import ToastService from 'src/services/ToastService';
import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/shadcn/components/ui/Form";
import {Checkbox} from 'src/shadcn/components/ui/Checkbox';
import {Popover, PopoverContent, PopoverTrigger} from 'src/shadcn/components/ui/Popover';
import {Button} from 'src/shadcn/components/ui/Button';
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem} from 'src/shadcn/components/ui/Command';
import {cn} from 'src/shadcn/lib/utils';
import {FormType} from "../BookingPage";

const BookingExtraOptions = ({form}: FormType) => {
  const [clients, setClients] = useState<UserType[]>([]);
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState("")
  const currentOption = React.useRef<{ id: number, email: string }>({} as { id: number, email: string })

  useEffect(() => {
    const controller = new AbortController();
    const asyncFn = async () => {
      try {
        LoadingService.show();
        const response = await Api.getResources<{ data: UserType[] }>('users');
        setClients(response.data);
      } catch (e) {
        ToastService.error(e);
      } finally {
        LoadingService.hide();
      }
    };
    asyncFn();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const asyncFn = async () => {
      try {
        LoadingService.show();
        const response = await Api.getResources<{ data: UserType[] }>('users', `filter=${search}`);
        setClients(response.data);
      } catch (error) {
        ToastService.error(error);
      } finally {
        LoadingService.hide();
      }
    };
    // Create a debounced version of the asyncFn
    const debouncedAsyncFn = _.debounce(asyncFn, 100);
    debouncedAsyncFn();
    return () => {
      debouncedAsyncFn.cancel(); // This is how you cancel a lodash debounced function
      controller.abort();
    };
  }, [search]);

  const clientId = form.watch("clientId")
  return (
    <>
      <FormField
        control={form.control}
        name={`clientId`}
        render={({field}) => (
          <FormItem className="flex flex-col">
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="justify-between"
                  >
                    <>
                      {clientId
                        ? [...clients, currentOption.current].find((client) => {
                          return client?.id?.toString() === clientId.toString()
                        })?.email
                        : "Seleziona cliente..."}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                    </>
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="p-0">
                <Command shouldFilter={false}>
                  <CommandInput value={search} onValueChange={setSearch} placeholder="Cerca cliente..."/>
                  <CommandEmpty>Nessun cliente trovato.</CommandEmpty>
                  <CommandGroup>
                    {clients.map((client) => (
                      <CommandItem
                        key={client.id.toString()}
                        value={client.id.toString()}
                        onSelect={(currentValue) => {
                          const newValue = currentValue === clientId ? "" : currentValue
                          form.setValue("clientId", newValue)
                          const option = clients.find((client) => client.id.toString() === newValue)
                          // we keep a reference to the current option so when searching we can still find it
                          if (option) {
                            currentOption.current = option
                          }
                          setOpen(false)
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            clientId === client.id.toString() ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {client.email}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage/>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`recurrent`}
        render={({field}) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                Ricorrente
              </FormLabel>
            </div>
          </FormItem>
        )}
      />
    </>
  );
};

export default BookingExtraOptions;
