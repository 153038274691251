import { Link, useLocation } from 'react-router-dom';
import i18n from '../i18n';
import Routes from '../routes';

const navigatorData = [
  {
    to: Routes.ADMIN_RESOURCE_LIST('app_settings'),
    label: i18n.t('admin.nav.appSettings'),
  },
  {
    to: Routes.ADMIN_RESOURCE_LIST('bookings'),
    label: i18n.t('admin.nav.bookings'),
  },
  {
    to: Routes.ADMIN_RESOURCE_LIST('users'),
    label: i18n.t('admin.nav.users'),
  },
  {
    to: Routes.ADMIN_RESOURCE_LIST('events'),
    label: i18n.t('admin.nav.events'),
  },
  {
    to: Routes.ADMIN_RESOURCE_LIST('weekly_availabilities'),
    label: i18n.t('admin.nav.weeklyAvailabilities'),
  },
  {
    to: Routes.ADMIN_RESOURCE_LIST('payments'),
    label: i18n.t('admin.nav.payments'),
  },
];

export const AdminNav = () => {
  const { pathname } = useLocation();
  return (
    <div className="flex flex-wrap gap-5 my-5">
      {navigatorData.map((d: any) => {
        return (
          <Link
            key={d.label}
            to={d.to}
            className={`flex items-center leading-snug  border-b-2  hover:border-primary ${
              pathname.includes(d.to) ? 'border-primary' : 'border-transparent '
            }`}
          >
            <div className="font-serif cursor-pointer ">{d.label}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default AdminNav;
