import { t } from 'i18next';
import React from 'react';

import {Button} from '../../shadcn/components/ui/Button';
import {Input} from 'src/shadcn/components/ui/Input';

type TableButtonProps = {
  resource: string;
}  &
  any;

export const TableButton = ({
  buttonText,
  onClick,
  ...props
}: TableButtonProps) => {
  return <Button variant='secondary' onClick={onClick} {...props} >{buttonText}</Button>;
};

type TableGlobalFilterProps = {
  globalFilterValue: string;
  onChangeGlobalFilter: (e: any) => void;
  placeholder?: string;
  className?: string;
};

export const TableGlobalFilter = ({
  globalFilterValue,
  onChangeGlobalFilter,
  placeholder,
  className = ''
}: TableGlobalFilterProps) => {
  return (
      <Input
        placeholder={placeholder || t('table.globalFilterPlaceholder')}
        value={globalFilterValue}
        onChange={(e: any) =>
          onChangeGlobalFilter && onChangeGlobalFilter(e.target.value)
        }
        className={className}
      />
  );
};

