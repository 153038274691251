import AuthApi from './AuthApi/LoginService';
import {Form} from 'src/shadcn/components/ui/Form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Label} from 'src/shadcn/components/ui/Label';
import {useForm} from 'react-hook-form';
import {Input} from 'src/shadcn/components/ui/Input';
import {useNavigate, useLocation} from 'react-router-dom';
import Routes from '../../routes';
import {GRID_ONE_COL} from '../../shared/constants/constant';
import i18n from '../../i18n';
import ToastService from '../../services/ToastService';
import {t} from 'i18next';
import {Typography} from "../../shadcn/components/ui/Typography";
import {Button} from "../../shadcn/components/ui/Button";

type InitialFormType = {
  password: string;
  passwordConfirmation: string;
};

let schema = yup.object().shape({
  password: yup.string().required().min(6),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

const ResetPasswordPage = () => {
  const form = useForm<InitialFormType>({
    mode: 'onChange',
    //@ts-ignore
    resolver: yupResolver(schema),
  });

  const {register, handleSubmit, formState} = form
  const search = useLocation().search;
  const navigate = useNavigate();
  const {isValid, errors} = formState;
  const resetPasswordToken = new URLSearchParams(search).get('reset_token');

  const onSubmit = async (value: InitialFormType) => {
    try {
      await AuthApi.resetPassword({
        resetPasswordToken,
        password: value.password,
        passwordConfirmation: value.passwordConfirmation,
      });
      ToastService.success(i18n.t('resetPassword.passwordChanged'));
      navigate(Routes.LOGIN);
    } catch (e: any) {
      ToastService.error(e);
    }
  };

  return (
    <Form {...form}>
      <form className={GRID_ONE_COL} onSubmit={handleSubmit(onSubmit)}>

        <Typography variant='h3' as='h1'>
          {i18n.t('resetPassword.title')}</Typography>
        <div>
          <Label
            className="block text-sm font-bold mb-2"
            htmlFor="password"
          >
            {i18n.t('resetPassword.form.password')}
          </Label>
          <Input
            id="password"
            autoComplete="current-password"
            type="password"
            {...register('password', {required: true})}
          />
          {errors.password?.type === 'required' && t('formErrors.required')}
          {errors.password?.type === 'min' &&
            t('formErrors.passwordMinLen', {chars: 6})}
        </div>
        <div>
          <Label
            className="block text-sm font-bold mb-2"
            htmlFor="passwordConfirmation"
          >
            {i18n.t('resetPassword.form.passwordConfirmation')}
          </Label>
          <Input
            id="passwordConfirmation"
            autoComplete="current-password"
            type="password"
            {...register('passwordConfirmation', {required: true})}
          />
          {errors.password?.type === 'required' && t('formErrors.required')}
          {errors.passwordConfirmation?.type === 'oneOf' &&
            t('signUp.passwordConfirmationError')}
        </div>
        <Button
          disabled={form.formState.isSubmitted}
          type="submit"
        >{t('resetPassword.resetPasswordButton')}</Button>
      </form>
    </Form>
  );
};

export default ResetPasswordPage;
