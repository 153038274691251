import {
  ColumnDef,
  flexRender,
  getCoreRowModel, OnChangeFn, PaginationState, RowSelectionState, SortingState,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn/components/ui/TableShadcn"
import {TablePagination} from "./TablePagination";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  setRowSelection?: OnChangeFn<RowSelectionState>
  rowSelection?: RowSelectionState,
  setPagination?: OnChangeFn<PaginationState>,
  pagination?: PaginationState,
  sorting?: SortingState,
  setSorting?: OnChangeFn<SortingState>
  totalPages?: number
}

export function DataTable<TData, TValue>(
  {
    columns,
    data,
    setRowSelection = () => {},
    rowSelection = {},
    setPagination = () => {},
    pagination = {
      pageIndex: 0,
      pageSize: 10
    },
    sorting,
    setSorting,
    totalPages
  }: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      sorting: sorting,
      rowSelection: rowSelection,
      pagination: pagination
    }
  })

  // nice table example https://ui.shadcn.com/docs/components/data-table
  return (
    <div className="rounded-md border overflow-auto">
      <Table>
        <TableHeader className='bg-primary/20'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination table={table}/>
    </div>
  )
}
