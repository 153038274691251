import React, {useMemo} from 'react';
import {AiFillDelete, AiFillEdit} from 'react-icons/ai';
import {t} from 'i18next';
import {ColumnDef, PaginationState, SortingState} from "@tanstack/react-table";

import {
  useTableGlobalFilter,
  useResourceTable,
  createQueryParameters,
  createQueryString,
} from '../../../components/table/tableUtils';
import {DataTable} from '../../../components/table/Table';

import {TableButton} from '../../../components/table/TableExtraComponents';
import {EventType, WeeklyAvailabiliyType} from "src/shared/types/types";
import ConfirmAlert, {useConfirmAlert} from "src/components/ConfirmAlert";
import { Typography } from 'src/shadcn/components/ui/Typography';
const Events = () => {
  const {globalFilterValue, onChangeGlobalFilter} = useTableGlobalFilter();
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [resourceToDelete, setResourceToDelete] = React.useState<EventType>()
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })
  const {hide, show, open, toggleVisibility} = useConfirmAlert()

  const queryString = useMemo(() => {
    const queryParameters = createQueryParameters({
      globalFilterValue,
      sorting,
      pagination
    })
    return createQueryString(queryParameters)
  }, [globalFilterValue, sorting, pagination])

  const {goToNew, deleteResource, goToEdit, data} = useResourceTable('events', queryString);

  const columns: ColumnDef<EventType>[] = useMemo(
    () => [
      {
        header: t('eventTable.name'),
        accessorKey: 'name',
      },
      {
        header: t('eventTable.duration'),
        accessorKey: 'duration',
      },
      {
        header: t('eventTable.incrementAmount'),
        accessorKey: 'increment_amount',
      },
      {
        id: "edit",
        enableHiding: false,
        cell: ({row}) => {
          const event = row.original
          return (
            <AiFillEdit className='cursor-pointer' onClick={() => {
              goToEdit(event.id)
            }}/>
          )
        },
      },
      {
        id: 'delete',
        cell: ({row}) => {
          const event = row.original
          return (
            <AiFillDelete
              onClick={() => {
                setResourceToDelete(event)
                show()
              }}/>
          );
        }
      },
    ],
    [deleteResource, goToEdit]
  );

  return (
    <>
      <ConfirmAlert
        onChange={toggleVisibility}
        open={open} onCancel={hide}
        onConfirm={async () => {
          resourceToDelete && await deleteResource(resourceToDelete.id);
          hide()
        }}
        alertTitle={t('general.confirmDeleteTitle', {resource: 'questo orario settimanale'})}
        alertDescription={
          <>
            <Typography>ID: {resourceToDelete?.id}</Typography>
            <Typography>NOME: {resourceToDelete?.name}</Typography>
          </>
        }
      />
      <TableButton
        onClick={goToNew}
        resource="events"
        buttonText={'create'}
        className="ml-auto"
      />
      <DataTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />

    </>
  );
};

export default Events;
