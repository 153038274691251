import React, {useContext, useEffect, useMemo} from 'react';
import {AiFillDelete} from 'react-icons/ai';
import {t} from 'i18next';
import {ArrowUpDown} from "lucide-react";
import {ColumnDef, PaginationState, SortingState} from "@tanstack/react-table"
import {toWeekDayLong, toHourMinute, toDateShort} from 'src/shared/helpers/utils';
import {Popover, PopoverContent, PopoverTrigger} from 'src/shadcn/components/ui/Popover';

import {
  useTableGlobalFilter,
  useResourceTable,
  createQueryParameters,
  createQueryString,
} from 'src/components/table/tableUtils';
import {DataTable} from 'src/components/table/Table';
import {BookingType} from "src/shared/types/types";
import {TableGlobalFilter} from 'src/components/table/TableExtraComponents';
import ConfirmAlert, {useConfirmAlert} from "src/components/ConfirmAlert";
import {Typography} from "src/shadcn/components/ui/Typography";
import {Button} from 'src/shadcn/components/ui/Button';
import {Calendar} from 'src/shadcn/components/ui/Calendar';
import {CalendarIcon} from "lucide-react";
import {addDays, format} from "date-fns";
import {cn} from 'src/shadcn/lib/utils';
import {DateRange} from "react-day-picker";
import Api from "src/shared/api/Api";
import LoadingService from "src/components/loading/LoadingService";
import ToastService from "src/services/ToastService";
import {Checkbox} from 'src/shadcn/components/ui/Checkbox';
import {AuthContext} from "src/authentication/AuthContext";
import {userIsAdmin} from "src/shared/constants/constant";

type Props = {
  isClient?: boolean
}
const Bookings = ({isClient = false}: Props) => {
  const {globalFilterValue, onChangeGlobalFilter} = useTableGlobalFilter();
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [resourceToDelete, setResourceToDelete] = React.useState<BookingType>()
  const {hide, show, open, toggleVisibility} = useConfirmAlert()
  const {user} = useContext(AuthContext);
  const [pagination, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const inAYear = addDays(new Date(), 360)
  const inAWeek = addDays(new Date(), 7)
  const initialDate =  {
    from: new Date(),
    // default one week of bookings
    to: isClient? inAYear : inAWeek
  }
  const [date, setDate] = React.useState<DateRange | undefined>(initialDate)
  const previousDateRef = React.useRef<DateRange | undefined>(date)
  const [onlyCurrentUserBookings, setOnlyCurrentUserBookings] = React.useState<boolean>(true)

  const queryString = useMemo(() => {
    const queryParameters = createQueryParameters({
      globalFilterValue,
      range: date,
      sorting,
      pagination,
      onlyCurrentUserBookings
    })
    return createQueryString(queryParameters)
  }, [globalFilterValue, sorting, pagination, date, onlyCurrentUserBookings])

  const {data, deleteResource, totalPages, refetchResources} = useResourceTable('bookings', queryString);

  const columns: ColumnDef<BookingType>[] = useMemo(
    () => [
      {
        header: t('bookingTable.id'),
        accessorKey: 'id',
      },
      {
        header: t('bookingTable.day'),
        accessorKey: 'start',
        id: t('bookingTable.day'),
        cell: (info: any) => toWeekDayLong(info.getValue()),
      },
      {
        header: ({column}) => {
          return (
            <Button
              variant="ghost"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              {t('bookingTable.date')}
              <ArrowUpDown className="ml-2 h-4 w-4"/>
            </Button>
          )
        },
        accessorKey: 'start',
        cell: (info: any) => toDateShort(info.getValue()),
      },
      {
        header: t('bookingTable.start'),
        // for columns with same data, we need to provide unique id and use accessorFn
        accessorFn: (row) => row.start,
        id: "dateStart",
        cell: (info) => toHourMinute(info.getValue() as string),
      },
      {
        header: t('bookingTable.end'),
        accessorKey: 'end',
        cell: (info) => toHourMinute(info.getValue() as string),
      },
      {
        header: t('bookingTable.userEmail'),
        accessorKey: 'user_email',
      },
      {
        header: t('bookingTable.paymentStatus'),
        accessorKey: 'payment_status',
      },
      {
        header: t('bookingTable.event'),
        accessorKey: 'event_name',
      },
      {
        header: t('bookingTable.trainer'),
        accessorKey: 'trainer_email',
      },
      {
        header: t('bookingTable.recurrent'),
        accessorKey: 'recurrent',
      },
      {
        id: "delete",
        cell: ({row}) => {
          const booking = row.original
          return (
            <AiFillDelete
              onClick={() => {
                setResourceToDelete(booking)
                show()
              }}/>
          );
        },
      },
    ],
    [show]
  );

  if(date) {
    previousDateRef.current = date
  }

  return (
    <>
      <ConfirmAlert
        onChange={toggleVisibility}
        open={open} onCancel={hide}
        confirmButtonLabel={resourceToDelete?.recurrent && 'Solo questa data'}
        onConfirm={async () => {
          try {
            LoadingService.show();
            if (resourceToDelete) {
              if (resourceToDelete?.recurrent) {
                await Api.createResource('recurrent_booking_exceptions', {
                  booking_id: resourceToDelete.id,
                  start: resourceToDelete.start,
                  end: resourceToDelete.end,
                })
              } else {
                await Api.deleteResource('bookings', resourceToDelete.id);
              }
              await refetchResources()
            }
          } catch (error) {
            ToastService.error(error);
          } finally {
            hide()
            LoadingService.hide();
          }
        }}
        secondaryActionLabel={resourceToDelete?.recurrent && 'Tutte le prenotazioni'}
        onConfirmSecondayAction={async () => {
          resourceToDelete && await deleteResource(resourceToDelete.id);
          hide()
        }}
        alertTitle={'Sicuro di voler cancellare la prenotazione?'}
        alertDescription={<Typography>DATA: {resourceToDelete && toDateShort(resourceToDelete.start)}</Typography>}
      />
      {
        (user && userIsAdmin(user.role_id)) &&
          <div className="flex items-center space-x-2">
              <Checkbox
                  checked={onlyCurrentUserBookings}
                  onCheckedChange={(checked) => setOnlyCurrentUserBookings(checked as boolean)}
              />
              <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                  Solo le mie prenotazioni
              </label>
          </div>
      }
      <div className='flex gap-2'>
        <TableGlobalFilter
          globalFilterValue={globalFilterValue}
          onChangeGlobalFilter={(e) => {
            if (e) {
              setDate(undefined)
              onChangeGlobalFilter(e)
            } else {
              setDate(previousDateRef.current)
              onChangeGlobalFilter(e)
            }
          }}
          placeholder='Cerca per email'
        />
        <div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={"outline"}
                className={cn(
                  "w-[300px] justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4"/>
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, "LLL dd, y")} -{" "}
                      {format(date.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(date.from, "LLL dd, y")
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={(v) => {
                  setDate(v)
                }}
                numberOfMonths={1}
              />
            </PopoverContent>
          </Popover>
          {!date && !globalFilterValue && <Typography variant='smallText' className={'text-destructive'}>Seleziona una data</Typography>}
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        totalPages={totalPages}
        sorting={sorting}
        setSorting={setSorting}
      />
    </>
  );
};

export default Bookings;
