import {Moon, Sun} from "lucide-react"

import {Button} from "../shadcn/components/ui/Button";
import {useTheme} from "../pages/booking/components/ThemeProvider";
import React from "react";

export function ModeToggle() {
  const {theme, setTheme} = useTheme()

  return (
    <Button onClick={() => {
      if (theme === "light") setTheme("dark")
      else setTheme("light")
    }} variant="ghost" size="sm">
      <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:rotate-90 dark:scale-0"/>
      <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"/>
      <span className="sr-only">Toggle theme</span>
    </Button>
  )
}
