import React from 'react';
import AppRouter from './AppRouter';
import ErrorBoundary from './components/ErrorBoundary';
import Authentication from './authentication/Authentication';
import * as yup from 'yup';
import { AppSettingsProvider } from './components/AppSettingsContext';

// Set global configuration for Yup
yup.setLocale({
  // Override the default required message for all validations
  mixed: {
    required: 'Campo obbligatorio', // Change this to your desired message
  },
  // You can also add or override messages for other validators as needed
});

function App() {
  return (
    <ErrorBoundary>
      <Authentication>
        <AppSettingsProvider>
          <AppRouter/>
        </AppSettingsProvider>
      </Authentication>
    </ErrorBoundary>
  );
}

export default App;
