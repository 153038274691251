import React, { useContext } from 'react';

import { AuthContext } from '../../authentication/AuthContext';
import {Typography} from "../../shadcn/components/ui/Typography";
import Bookings from "../../admin/adminPages/bookings/Bookings";

const UserPage = () => {
  const { user } = useContext(AuthContext);

  if(!user) return <Typography as='h1'>Utente non trovato</Typography>
  return (
    <Bookings isClient/>
  );
};

export default UserPage;
