import HttpService from 'src/services/HttpService';
class Api {
  public getResources<T>(resource: string, param?: string | number): Promise<T> {
    const url = param ? `/${resource}?${param}` : `/${resource}`;
    return HttpService.get(url);
  }
  public getResource<T>(resource: string, id: number | string, param?: string | number): Promise<T> {
    const url = param ? `/${resource}/${id}?${param}` : `/${resource}/${id}`;
    return HttpService.get(url);
  }
  public deleteResource<T>(resource: string, id: number): Promise<T> {
    return HttpService.delete(`/${resource}/${id}`);
  }
  // FILO chagne return type
  public editResource<T>(
    resource: string,
    id: string | number,
    payload: any
  ): Promise<T> {
    return HttpService.put(`/${resource}/${id}`, payload);
  }
  // FILO CHECK PAYLOAD TYPE, IT WAS T, IT SHOULD PROPABLY BE SOME PROPS OF T
  public createResource<T>(resource: string, payload: any): Promise<T> {
    return HttpService.post(`/${resource}`, payload);
  }
}

export default new Api();
