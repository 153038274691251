import React, { useEffect, useState } from 'react';

//import { useStyles } from './Loading.styles';
import LoadingService from './LoadingService';
import './loading.css';

/**
 *
 * (This loading is only needed if the Loading is going to be global(entire page)(preferable)
 * To create a coherent Loading and to remove the logistic of every components that needs the
 * loading use LoadingService.show() or LoadingService.hide() to control this components
 * everywhere in the javascript
 *
 * * (The Loading animation should be changed in case the designer decides so)
 *
 */

let nFetchs = 0;

const Loading: React.FC = () => {
  //const classes = useStyles();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const event = LoadingService.addListener((_loading: boolean) => {
      if (_loading) {
        if (nFetchs <= 0) {
          setLoading(true);
        }
        nFetchs++;
      } else {
        nFetchs--;
        if (nFetchs <= 0) {
          setLoading(false);
        }
      }
    });

    return () => {
      event.removeEventListener();
    };
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // TODO disabled background because on fast change seems like a black blink page
        // backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000,
      }}
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loading;
