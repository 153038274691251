import React, {useMemo} from 'react';
import {AiOutlineEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {ColumnDef, PaginationState, SortingState} from '@tanstack/react-table';
import {t} from 'i18next';


import {
  createQueryParameters, createQueryString,
  useResourceTable,
} from 'src/components/table/tableUtils';
import {TableButton} from 'src/components/table/TableExtraComponents';
import {WeeklyAvailabiliyType} from "src/shared/types/types";
import {DataTable} from "src/components/table/Table";
import ConfirmAlert, {useConfirmAlert} from "src/components/ConfirmAlert";
import {Typography} from "src/shadcn/components/ui/Typography";

const WeeklyAvailabilities = () => {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })
  const [resourceToDelete, setResourceToDelete] = React.useState<WeeklyAvailabiliyType>()
  const {hide, show, open, toggleVisibility} = useConfirmAlert()

  const queryString = useMemo(() => {
    const queryParameters = createQueryParameters({
      sorting,
      pagination
    })
    return createQueryString(queryParameters)
  }, [sorting, pagination])

  const {goToNew, deleteResource, goToEdit, data} = useResourceTable('weekly_availabilities', queryString);

  const columns: ColumnDef<WeeklyAvailabiliyType>[] = useMemo(
    () => [
      {
        header: t('weeklyAvailabilityTable.name'),
        accessorKey: 'name',
      },
      {
        header: t('weeklyAvailabilityTable.address'),
        accessorKey: 'address',
      },
      {
        id: 'edit',
        cell: ({row}) => {
          return (
            <AiOutlineEdit
              className='cursor-pointer'
              onClick={() => goToEdit(row.original.id)}/>
          );
        },
      },
      {
        id: 'delete',
        cell: ({row}) => {
          const weeklyAvailability = row.original
          return (
            <AiFillDelete
              onClick={() => {
                setResourceToDelete(weeklyAvailability)
                show()
              }}/>
          )
        },
      },
    ],
    [deleteResource, goToEdit]
  );

  return (
    <>
      <ConfirmAlert
        onChange={toggleVisibility}
        open={open} onCancel={hide}
        onConfirm={async () => {
          resourceToDelete && await deleteResource(resourceToDelete.id);
          hide()
        }}
        alertTitle={t('general.confirmDeleteTitle', {resource: 'questo orario settimanale'})}
        alertDescription={
          <>
            <Typography>ID: {resourceToDelete?.id}</Typography>
            <Typography>NOME: {resourceToDelete?.name}</Typography>
          </>
        }
      />
      <TableButton
        onClick={goToNew}
        resource="bookings"
        buttonText={'create'}
        className="ml-auto"
      />
      <DataTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />
    </>
  );
};

export default WeeklyAvailabilities;
