import React, { useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { v4 } from 'uuid';

import ToastService from 'src/services/ToastService';
import LoadingService from '../../components/loading/LoadingService';
import { t } from 'i18next';
import LocalStorageManager from '../../services/StorageService';
import { bookingInfo } from '../../shared/constants/constant';
import { useNavigate } from 'react-router-dom';
import routes from 'src/routes';
import {router} from "src/index";
import { Typography } from 'src/shadcn/components/ui/Typography';
import Api from "../../shared/api/Api";
import {useTheme} from "../booking/components/ThemeProvider";

//To use Element components, wrap your checkout page components in an Elements provider.
//Call loadStripe with your publishable key and pass the returned Promise to the Elements provider.

// Make sure to call loadStripe outside of a components’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const idempotencyKey = v4();
export default function StripeElementProvider({ children }: any) {
  const [clientSecret, setClientSecret] = useState('');
  const {theme} = useTheme()
  const navigate =useNavigate();
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)
  );

  useEffect(() => {
    const booking = LocalStorageManager.getItem(bookingInfo);
    if (!booking?.event_id) navigate(routes.HOME);
    else {
      const asyncFn = async () => {
        try {
          LoadingService.show();
          const response = await Api.createResource('stripe/create_payment_intent', {
            idempotency_key: idempotencyKey,
            event_id: booking.event_id,
          })
          //@ts-ignore
          setClientSecret(response.clientSecret);
        } catch (e) {
          ToastService.error(e);
        } finally {
          LoadingService.hide();
        }
      };
      asyncFn();
    }
    // FILO CHECK HERE, BEFORE WAS HISTORY
  }, [router]);

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance: { theme: theme === 'dark' ? 'night' : 'stripe' } }}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      ) : (
        <Typography>{t('stripeElementProvider.paymentLoading')}</Typography>
      )}
    </>
  );
}