import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/shadcn/components/ui/Tabs"
import WeeklyAvailabilityForm from "./WeeklyAvailabilityForm";
import React from "react";
import AvailabilityOverride from "./AvailabilityOverride";

const AvailabilityEditPage = () => {
  return (
    <Tabs defaultValue="default" >
      <TabsList className='w-full'>
        <TabsTrigger className='w-full' value="default">Default</TabsTrigger>
        <TabsTrigger className='w-full' value="specific">Particolari</TabsTrigger>
      </TabsList>
      <TabsContent value="default"><WeeklyAvailabilityForm /></TabsContent>
      <TabsContent value="specific"><AvailabilityOverride/></TabsContent>
    </Tabs>

  )
}

export default AvailabilityEditPage