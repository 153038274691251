import {Typography} from "../../../shadcn/components/ui/Typography";
import * as yup from "yup";
import {Form, FormControl, FormField, FormItem, FormLabel} from "src/shadcn/components/ui/Form";
import {Checkbox} from "src/shadcn/components/ui/Checkbox";
import {Button} from "../../../shadcn/components/ui/Button";
import {t} from "i18next";
import React, {useContext, useEffect} from "react";
import Api from "src/shared/api/Api";
import LoadingService from "src/components/loading/LoadingService";
import ToastService from "src/services/ToastService";
import {AppSettingsContext, AppSettingsFormType } from "src/components/AppSettingsContext";

const AppSettings = () => {
  const {form} = useContext(AppSettingsContext)
  const onSubmit = async (data: AppSettingsFormType) => {
    try {
      LoadingService.show();
      const response = await Api.createResource<AppSettingsFormType>('app_settings', {in_maintenance: data.in_maintenance})
      form.setValue('in_maintenance', response.in_maintenance)
      ToastService.success('App Settings aggiornato');
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  }

  return (
    <>
      <Typography variant='h5' as='h2'>App Settings</Typography>
      <Form {...form}>
        <form onSubmit={
          form.handleSubmit((data: any) => onSubmit(data))
        }>
          <FormField
            control={form.control}
            name={`in_maintenance`}
            render={({field}) => {
              return (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      Seleziona se il sito è in manutenzione
                    </FormLabel>
                  </div>
                </FormItem>
              )
            }}
          />
          <Button
            type="submit"
            className='mt-12'
          >{t('admin.weeklyAvailabilityForm.submitButton')}</Button>
        </form>
      </Form></>
  )
}

export default AppSettings