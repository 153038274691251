import { UserType } from '../shared/types/types';

export type SetUserType = (user: UserType) => void;
export type SetLogoutType = () => void;

class SessionService {
  protected _setUser: SetUserType = () => {};
  protected _onLogout: SetLogoutType = () => {};

  public onSetLogout(onLogout: SetLogoutType) {
    this._onLogout = onLogout;
  }

  public onSetUser(setUser: SetUserType) {
    this._setUser = setUser;
  }

  public login(user: UserType) {
    this._setUser(user);
  }

  public logOut() {
    this._onLogout();
  }
}

export default new SessionService();
