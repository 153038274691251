import { DateTime } from 'luxon';
import React, {useCallback, useEffect, useState} from 'react';
import ReactCalendar from 'react-calendar';


import {
  compareDates,
  isBeforeToday,
} from 'src/shared/helpers/utils';
import ToastService from 'src/services/ToastService';
import {FormType, NavigationActionsType} from '../BookingPage';
import LoadingService from 'src/components/loading/LoadingService';
import { AvailabilityType } from 'src/shared/types/types';
import { t } from 'i18next';
import 'react-calendar/dist/Calendar.css';
import 'src/pages/booking/components/calendar.css'
import Api from "../../../shared/api/Api";
import { Typography } from 'src/shadcn/components/ui/Typography';

type Props = {
  navigationActions: NavigationActionsType;
} & FormType;
function BoookingCalendar({ navigationActions, form }: Props) {
  const [montlyAvail, setMontlyAvail] = useState<AvailabilityType[]>([]);
  const {selectedDate, eventId, weeklyAvailabilityId} = form.watch()

  useEffect(() => {
    const controller = new AbortController();
    const asyncFunc = async () => {
      try {
        LoadingService.show();
        const selDate = DateTime.fromISO(selectedDate);
        const availabilities =  await Api.getResources<AvailabilityType[]>(`events/${eventId}/weekly_availability/${weeklyAvailabilityId}/available_times`, `start=${selDate}&end=${selDate}`)
        form.setValue('slots', availabilities[0].slots)
      } catch (error) {
        ToastService.error(error);
      } finally {
        LoadingService.hide();
      }
      return () => {
        controller.abort();
      };
    }

    asyncFunc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (date: Date) => {
    try {
      LoadingService.show();
      const selDate = DateTime.fromJSDate(date)
      const availabilities =  await Api.getResources<AvailabilityType[]>(`events/${eventId}/weekly_availability/${weeklyAvailabilityId}/available_times`, `start=${selDate}&end=${selDate}`)
      form.setValue('slots', availabilities[0].slots)
      form.setValue('selectedDate', selDate.toISO())
      LoadingService.hide();
      navigationActions.navigateForward();
    } catch (error) {
      ToastService.error(error);
    } finally {
    }
  };

  const getMontlyAvailabilities = async (startingDate: DateTime) => {
    try {
      LoadingService.show();
      const start = startingDate.startOf('month')
      const end = startingDate.endOf('month')
      const availabilities =  await Api.getResources<AvailabilityType[]>(`events/${eventId}/weekly_availability/${weeklyAvailabilityId}/available_times`, `start=${start}&end=${end}`)
      setMontlyAvail(availabilities);
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  useEffect(() => {
    getMontlyAvailabilities(DateTime.fromISO(selectedDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDateAvailable = useCallback((date: Date) => {
    const availableDate = montlyAvail.find((availability) =>
      compareDates(availability.time, date)
    );

    return (availableDate && availableDate.slots.length > 0 && !isBeforeToday(date.toISOString()));
  }, [montlyAvail])


  return (
    <div>
      <div className="flex gap-2 mb-2 items-center">
        <div className="legend" />
        <Typography className="text-xs">{t('calendar.availability')}</Typography>
      </div>
      <ReactCalendar
        onActiveStartDateChange={async ({ activeStartDate }) => {
          await getMontlyAvailabilities(DateTime.fromJSDate(activeStartDate));
        }}
        minDetail="month"
        maxDetail="month"
        defaultView="month"
        next2Label={null}
        prev2Label={null}
        showNeighboringMonth={false}
        tileClassName={({date}) => isDateAvailable(date) ? 'withAvailabilities' : null}
        tileDisabled={({ date }) => !isDateAvailable(date)}
        minDate={new Date()}
        onChange={handleChange}
        value={DateTime.fromISO(selectedDate).toJSDate()}
      />
    </div>
  );
}

export default BoookingCalendar;
