import React, { useMemo } from 'react';
import { TableGlobalFilter } from '../../../components/table/TableExtraComponents';
import { t } from 'i18next';
import { USER_ROLES_NAMES_MAP } from 'src/shared/constants/constant';
import {createQueryParameters, createQueryString, useResourceTable, useTableGlobalFilter} from '../../../components/table/tableUtils';
import {DataTable} from "../../../components/table/Table";
import {ColumnDef, PaginationState, SortingState} from "@tanstack/react-table";
import {EventType, UserType} from "../../../shared/types/types";

const Users = () => {
  const { globalFilterValue, onChangeGlobalFilter } = useTableGlobalFilter();
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })

  const queryString = useMemo(() => {
    const queryParameters = createQueryParameters({
      globalFilterValue,
      sorting,
      pagination
    })
    return createQueryString(queryParameters)
  }, [globalFilterValue, sorting, pagination])

  const {data, totalPages} = useResourceTable('users', queryString);

  const columns: ColumnDef<UserType>[] = useMemo(() => {
    return [
      {
        header: t('userTable.id'),
        accessorKey: 'id',
      },
      {
        header: t('userTable.name'),
        accessorKey: 'name',
      },
      {
        header: t('userTable.last_name'),
        accessorKey: 'last_name',
      },
      {
        header: t('userTable.email'),
        accessorKey: 'email',
      },
      {
        header: t('userTable.phone_number'),
        accessorKey: 'phone_number',
      },
      {
        header: t('userTable.address'),
        accessorKey: 'address',
      },
      {
        header: t('userTable.role'),
        accessorKey: 'role_id',
        cell: (info) => USER_ROLES_NAMES_MAP.get(info.getValue()),
      },
    ];
  }, []);

  return (
      <>
        <TableGlobalFilter
          globalFilterValue={globalFilterValue}
          onChangeGlobalFilter={onChangeGlobalFilter}
          placeholder='Cerca per email'
        />
        <DataTable
          columns={columns}
          totalPages={totalPages}
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
        />
      </>
  );
};
export default Users;
