import { createContext } from 'react';
import { UserType } from '../shared/types/types';

export type AuthContextState = {
  user: UserType | null;
};

export const initialState = {
  user: null,
};

export const AuthContext = createContext<AuthContextState>(initialState);
