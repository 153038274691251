import { toast } from 'react-toastify';

class ToastService {
  success(message: string) {
    toast.success(message);
  }

  error(error: any) {
    let errorMessage = error?.message?.message || error?.message?.error || error?.message || error?.statusText || error
    errorMessage =  typeof(errorMessage) !== 'string' ? JSON.stringify(errorMessage) : errorMessage
    toast.error(errorMessage);
  }
}

export default new ToastService();
