import React, {useContext} from 'react';
import appRoutes from './routes';
import {
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import Navbar from './components/NavBar';
import LoginPage, { SendEmailForResetPassord } from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import Footer from './components/Footer';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ContactPage from './pages/contact/ContactPage';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import ErrorHanlder from './components/ErrorHandler';
import BookingPage from './pages/booking/BookingPage';
import UserPage from './pages/user/UserPage';
import {adminRoutes, AdminLayout} from './admin/adminRouter/AdminRouter';
import HomePage from './pages/home/HomePage';
import  {paymentRoutes} from './pages/payment/PaymentRouter';
import { AuthContext } from './authentication/AuthContext';
import {Typography} from "./shadcn/components/ui/Typography";
import {UserType} from "./shared/types/types";
import {userIsAdminOrTrainer} from "./shared/constants/constant";

export const PrivateRoute = ({children, condition}: { children: JSX.Element, condition?: (user: UserType | null) => boolean }) => {
  const {user} = useContext(AuthContext);
  if (condition ? condition(user) : user) {
    return children
  }
  return <Typography>Non sei autorizzato</Typography>
};
const AppLayout = (props: any) => {
  return (
    <div className="flex-1 py-10 md:py-20 w-11/12 md:w-4/5 max-w-5xl m-auto">
      <div className="w-full">{props.children}</div>
    </div>
  );
};

type RotuerDataType = {
  path?: string;
  index?: boolean;
  element: JSX.Element;
  children?: React.ReactNode
}
const generateRoutes = (routes: RotuerDataType[]) => {
  return routes.map((route, i) => {
    return (
     // @ts-ignore
        <Route
            key={route.path || i}
            path={route.path}
            index={route.index}
            element={route.element}
        >
          {route.children}
        </Route>
    );
  });
}
const routes: RotuerDataType[] = [
  {
    path: appRoutes.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: appRoutes.PAYMENT,
    element: <PrivateRoute><Outlet/></PrivateRoute>,
    children: generateRoutes(paymentRoutes)
  },
  {
    path: appRoutes.BOOKING,
    element: <BookingPage />,
  },
  {
    path: appRoutes.SIGNUP,
    index: true,
    element: <SignupPage />,
  },
  {
    path: appRoutes.LOGIN,
    element: <LoginPage />,
  },
  {
    path: appRoutes.LOGIN_FORGOT_PASSWORD,
    element: <SendEmailForResetPassord />,
  },
  {
    path: appRoutes.CONTACT,
    element: <ContactPage />,
  },
  {
    path: appRoutes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: appRoutes.USER,
    element: <PrivateRoute><UserPage /></PrivateRoute>
  },
  {
    path: appRoutes.ADMIN,
    element: <PrivateRoute condition={(user) => user ? userIsAdminOrTrainer(user?.role_id) : false}><Outlet/></PrivateRoute>,
    children: <Route element={<AdminLayout/>}>{generateRoutes(adminRoutes)}
    </Route>
  },
  {
    path: appRoutes.HOME,
    element: <HomePage />,
  },
  {
    path: appRoutes.ROOT,
    index: true,
    element: <HomePage />
  },
];

const AppRouter = (): JSX.Element => {
  return (
      <Routes>
        <Route element={
          <>
            <Navbar/>
            <AppLayout>
              <Outlet/>
            </AppLayout>
            <ErrorHanlder/>
            <Footer/>
          </>
        }>
          {generateRoutes(routes)}
        </Route>
      </Routes>
  );
};

export default AppRouter;
