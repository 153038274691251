import {
  AlertDialog,
  AlertDialogContent, AlertDialogDescription,
  AlertDialogHeader, AlertDialogTitle,
} from "src/shadcn/components/ui/AlertDialog";
import React, {useContext} from "react";
import {AuthContext} from "../authentication/AuthContext";
import {userIsAdminOrTrainer} from "../shared/constants/constant";
import {useLocation} from "react-router-dom";
import routes from "../routes";


export const AppMaintanenceAlert = ({inMaintenance}: { inMaintenance: boolean }) => {
  const {user} = useContext(AuthContext);
  const { pathname } = useLocation();
  const isLoginPage = pathname === routes.LOGIN
  const isSignupPage = pathname === routes.SIGNUP
  const open = (!user || !userIsAdminOrTrainer(user?.role_id)) && !isLoginPage
     && !isSignupPage && inMaintenance
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>App in Manutenzione</AlertDialogTitle>
          <AlertDialogDescription>
            App in fase di manutenzione, riprova più tardi
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>

  )
}