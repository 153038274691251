import React, { useMemo } from 'react';
import i18n from 'src/i18n';
import {
  createQueryParameters,
  createQueryString,
  useResourceTable,
  useTableGlobalFilter
} from '../../../components/table/tableUtils';
import { toDateShort } from 'src/shared/helpers/utils';
import {PaymentType} from "../../../shared/types/types";
import { DataTable } from '../../../components/table/Table';
import {ColumnDef, PaginationState, SortingState} from "@tanstack/react-table";
import { TableGlobalFilter } from 'src/components/table/TableExtraComponents';
import {Button} from "../../../shadcn/components/ui/Button";
import {t} from "i18next";
import {ArrowUpDown} from "lucide-react";

const Payments = () => {
  const { globalFilterValue, onChangeGlobalFilter } = useTableGlobalFilter();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  } as PaginationState)
  const [sorting, setSorting] = React.useState<SortingState>([])

  const queryString = useMemo(() => {
    const queryParameters = createQueryParameters({
      globalFilterValue,
      sorting,
      pagination
    })
    return createQueryString(queryParameters)
  }, [globalFilterValue, sorting, pagination])

  const { data, totalPages} = useResourceTable('payments', queryString);

  const columns: ColumnDef<PaymentType>[] = useMemo(
    () => [
      {
        header: i18n.t('paymentTable.id'),
        accessorKey: 'id',
      },
      {
        id: 'updated_at',
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              {t('paymentTable.updated_at')}
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
        cell: ({row}) => toDateShort(row.original.updated_at),
      },
      {
        header: i18n.t('paymentTable.transaction_id'),
        accessorKey: 'transaction_id',
      },
      {
        header: i18n.t('paymentTable.booking_id'),
        accessorKey: 'booking_id',
      },
      {
        header: i18n.t('paymentTable.user_id'),
        accessorKey: 'user_id',
      },
      {
        header: i18n.t('paymentTable.user_email'),
        accessorKey: 'user_email',
      },
      {
        header: i18n.t('paymentTable.status'),
        accessorKey: 'status',
      },
    ],
    []
  );

  return (
    <>
      <TableGlobalFilter
        globalFilterValue={globalFilterValue}
        onChangeGlobalFilter={onChangeGlobalFilter}
        placeholder='Cerca per email'
      />
      <DataTable
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        totalPages={totalPages}
        sorting={sorting}
        setSorting={setSorting}
      />
    </>
  );
};

export default Payments;
