import React from 'react';
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'src/shadcn/components/ui/Select';
import {convertMinutesToHoursMinutes} from "../shared/helpers/utils";
const createHourOptions = (): number[] => {
  const minutes = 1320;
  const options = [360];
  let flag = 0;
  while (options[options.length - 1] < minutes) {
    const newValue = options[options.length - 1] + 15;
    options.push(newValue);
    flag += 1;
  }
  return options;
};
export const hourOptions = createHourOptions();

type SelectHourType = {
  onChange: (e: string) => void;
  value: string | number;
}

const SelectHour = React.forwardRef((props: SelectHourType, ref: any) => {

  return (
    <Select value={props.value?.toString()} onValueChange={(e) => e && props.onChange(e)}>
        <SelectTrigger ref={ref}>
          <SelectValue placeholder="Evento"/>
        </SelectTrigger>
      <SelectContent position={'item-aligned'}>
        {hourOptions.map((minutes) => (
          <SelectItem key={minutes} value={minutes?.toString()}>
            {convertMinutesToHoursMinutes(minutes)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

export default SelectHour;
