import {DateRange} from "react-day-picker";

export type TimeRangeType = {
  start: string;
  end: string;
};

export type BookingType = {
  id: number;
  start: string;
  end: string;
  user_id: number;
  user_email: string;
  event_id: number;
  event_name: string;
  weekly_availability_id: number;
  trainer_id: number;
  trainer_email: string;
  calendarEventId: string;
  recurrent: boolean;
  created_at?: string;
  updated_at?: string;
  payment_status: string;
  is_duplicate?: boolean;
};

export type EventType = {
  id: number;
  name: string;
  duration: number;
  increment_amount: number;
  trainer_id: number;
  trainer_email: string;
  trainer_name: string;
  price: number;
  weekly_availabilities: WeeklyAvailabiliyType[];
};

export type UserType = {
  id: number;
  name: string;
  last_name: string;
  address: string;
  phone_number: string;
  email: string;
  created_at: string;
  updated_at: string;
  role_id: number;
};

export type AvailabilityOverrideType = {
  id: number;
  date: DateRange;
  start_date: string;
  end_date: string;
  weekly_availability_id: number;
};

export type WeeklyAvailabiliyType = {
  id: number;
  name: string;
  hours: HourType[];
  availability_overrides: AvailabilityOverrideType[];
  address: string;
};

export type DayType = {
  id: number;
  name: string;
  hours: HourType[];
};

export type HourType = {
  id: number;
  day_id?: number;
  weekly_availability_id: number;
  start: number;
  end: number;
  // can be undefined, some hours are not associated to an event but only to a weekly availability, "they are waiting to be associated to an event"
  event_id?: number;
  date?: string;
  updated_at?: string;
  created_at?: string;
  availability_override_id?: number;
};

export type FormExtraProps = { touched?: boolean, createdLocally?: boolean }

export const hourToPayload = ({ id, weekly_availability_id, availability_override_id, start, end, day_id, event_id }: HourType & FormExtraProps) => ({
  id,
  weekly_availability_id,
  availability_override_id,
  start,
  end,
  day_id,
  event_id
});

export type AvailabilityType = {
  day_id: number;
  bookings: BookingType[];
  time: string;
  slots: TimeRangeType[];
};

export type PaymentType = {
  booking_id: number;
  created_at: string;
  id: number;
  status: string;
  transaction_id: string;
  updated_at: string;
  user_id: number;
};
