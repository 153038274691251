import React, {useEffect, useMemo, useState} from 'react';
import { t } from 'i18next';
import {Input} from 'src/shadcn/components/ui/Input';
import { useForm } from 'react-hook-form';
import {Button} from 'src/shadcn/components/ui/Button';
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'src/shadcn/components/ui/Select';
import {
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from 'src/shadcn/components/ui/Form';
import {useNavigate, useParams} from "react-router-dom";
import LoadingService from "../../../components/loading/LoadingService";
import {EventType, UserType} from "../../../shared/types/types";
import ToastService from "../../../services/ToastService";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Api from "../../../shared/api/Api";
import routes from "../../../routes";
import RenderCounter from "../../../components/RenderCount";

const useEventForm = () => {
  const [trainers, setTrainers] = useState<UserType[]>([]);
  let { id } = useParams<{ id: string }>();
  const  navigate  = useNavigate();

  type FormType = {
    name: string;
    duration: number;
    increment_amount: number;
    trainer_id: string;
    price: number;
  };

  let schema = yup.object().shape({
    name: yup.string().required(),
    trainer_id: yup.string().required(),
    duration: yup.number().required(),
    increment_amount: yup.number().required(),
    price: yup.number().required(),
  }).required();

  const form = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      duration: undefined,
      increment_amount: 0,
      price: 50,
      trainer_id: undefined,
    }
  });

  const getAndSetEvent = useMemo(() => {
    return async () => {
      if(!id) return
      const event = await Api.getResource<EventType>('events', id);
      form.reset({
        name: event.name,
        duration: event.duration,
        increment_amount: event.increment_amount,
        trainer_id: event.trainer_id.toString(),
        price: event.price
      })
    };
  }, [form, id]);

  useEffect(() => {
    const controller = new AbortController();
    const asyncFn = async () => {
      LoadingService.show();
      try {
        const response = await Api.getResources<{data: UserType[]}>(
          'users',
          'only_trainers=true'
        );
        setTrainers(response.data);
        await getAndSetEvent();
      } catch (e) {
        ToastService.error(e);
      } finally {
        LoadingService.hide();
      }
      return () => {
        controller.abort();
      };
    };
    asyncFn();
  }, [form, getAndSetEvent, id]);

  const onSubmitEdit = async (data: FormType ) => {
    if(!id) return
    try {
      LoadingService.show();
      await Api.editResource<FormType>('events', id, data);
      await getAndSetEvent();
      ToastService.success('Updated');
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  const onSubmitCreate = async (data: FormType) => {
    try {
      LoadingService.show();
      await Api.createResource<FormType>('events', data);
      ToastService.success('Created');
      navigate(`/admin/${routes.ADMIN_RESOURCE_LIST('events')}`);
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  }

  const onSubmit = id ? onSubmitEdit : onSubmitCreate
  return {
    form,
    trainers,
    onSubmit,
  }
}

const EventForm = () => {
const {trainers, form, onSubmit} = useEventForm()

  return (
    <Form {...form}>
      <form className="form flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="trainer_id"
          render={({field}) => {
            return (
              <FormItem>
                <FormLabel>{t('eventForm.trainer')}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value?.toString()}>
                    <SelectTrigger>
                      <SelectValue placeholder={t('eventForm.trainer')}/>
                    </SelectTrigger>
                    <SelectContent>
                      {trainers?.map((v) => (
                        <SelectItem key={v.id} value={v.id.toString()}>
                          {v.email}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage/>
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="name"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('eventForm.name')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('eventForm.name')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('eventForm.duration')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('eventForm.duration')}
                  type="number"
                  id="duration"
                  {...field}
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="price"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('eventForm.price')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('eventForm.price')}
                  type="number"
                  id="price"
                  {...field}
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="increment_amount"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('eventForm.increment')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('eventForm.increment')}
                  type="number"
                  id="increment_amount"
                  {...field}
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <Button type='submit' className='mt-2'>{t('eventForm.submit')}</Button>
      </form>
    </Form>
  );
};

export default EventForm;
