import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingService from '../loading/LoadingService';
import ToastService from '../../services/ToastService';
import Api from '../../shared/api/Api';
import Routes from '../../routes';
import {DateRange} from "react-day-picker";
import {DateTime} from "luxon";


type QueryParamsInput = {
  globalFilterValue?: string;
  range?: DateRange;
  sorting?: { id: string; desc?: boolean }[];
  pagination?: { pageIndex: number; pageSize: number };
  onlyCurrentUserBookings?: boolean;
};

export function createQueryParameters(
  {
    globalFilterValue,
    sorting = [],
    range,
    pagination = {pageIndex: 0, pageSize: 0},
    onlyCurrentUserBookings
  }: QueryParamsInput): Record<string, string> {

  const queryParameters: Record<string, string> = {};

  if(range && range.from) {
    queryParameters['day_start'] = DateTime.fromJSDate(range.from).toLocaleString();
    queryParameters['day_end'] = range.to ? DateTime.fromJSDate(range.to).toLocaleString() : DateTime.fromJSDate(range.from).toLocaleString();
  }

  if (globalFilterValue) {
    queryParameters['filter'] = globalFilterValue;
  }

  if (sorting.length > 0 && sorting[0]?.id) {
    queryParameters['sort_by'] = sorting[0].id;
    queryParameters['sort_order'] = sorting[0].desc ? 'desc' : 'asc';
  }

  if (pagination.pageIndex >= 0) {
    queryParameters['page'] = (pagination.pageIndex + 1).toString();
  }

  if (pagination.pageSize > 0) {
    queryParameters['limit'] = pagination.pageSize.toString();
  }

  if(onlyCurrentUserBookings) {
    queryParameters['only_current_user_bookings'] = 'true';
  }

  return queryParameters;
}

export const createQueryString = (queryParameters: Record<string, string>) => {
  return Object.entries(queryParameters)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export function useResourceTable<ResourceType>(resource: string, param?: string) {
  const [resources, setResources] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const navigate = useNavigate();

  const goToNew = () => navigate(Routes.ADMIN_RESOURCE_NEW(resource));

  const goToEdit = (id: number) =>
    navigate(Routes.ADMIN_RESOURCE_VIEW(resource, id));

  const deleteResource = async (
    id: number,
    enpointPath?: string
  ) => {
    try {
      const pickedResource = enpointPath || resource;
      LoadingService.show();
      await Api.deleteResource(pickedResource, id);
      ToastService.success('Cancellato!');
      const resources = await Api.getResources<{data: ResourceType[], total_pages: number}>(resource, param);
      setResources(resources.data);
      setTotalPages(resources.total_pages)
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  const refetchResources = async () => {
    const resources = await Api.getResources<{ data: ResourceType[], total_pages: number }>(resource, param);
    setResources(resources.data);
    setTotalPages(resources.total_pages)
  }


  useEffect(() => {
    const controller = new AbortController();
    const asyncFn = async () => {
      try {
        LoadingService.show();
        const resources = await Api.getResources<{data: ResourceType[], total_pages: number}>(resource, param);
        setResources(resources.data);
        setTotalPages(resources.total_pages)
      } catch (error) {
        ToastService.error(error);
      } finally {
        LoadingService.hide();
      }
    };
    // Create a debounced version of the asyncFn
    const debouncedAsyncFn = _.debounce(asyncFn, 300);
    debouncedAsyncFn();
    return () => {
      debouncedAsyncFn.cancel(); // This is how you cancel a lodash debounced function
      controller.abort();
    };  }, [resource, param]);


  return {
    data: resources,
    setResources,
    goToNew,
    deleteResource,
    goToEdit,
    totalPages,
    refetchResources
  };
}

export const useTableGlobalFilter = () => {
  const [globalFilterValue, setGlobalFilter] = useState('');
  const onChangeGlobalFilter = (value: string) => setGlobalFilter(value);

  return { globalFilterValue, onChangeGlobalFilter };
};
