import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { t } from 'i18next';
import Api from 'src/shared/api/Api';
import LocalStorageManager from '../../services/StorageService';
import { bookingInfo } from 'src/shared/constants/constant';
import { Alert, AlertDescription, AlertTitle } from 'src/shadcn/components/ui/Alert';
import ToastService from 'src/services/ToastService';
import LoadingService from 'src/components/loading/LoadingService';

function PaymentSuccessPage() {
  const stripe = useStripe();

  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  const createPayment = async (
    status?: string,
    transaction_id?: string,
    booking_id?: string
  ) => {
    try {
      LoadingService.show();
      await Api.createResource('payments', {
        status,
        transaction_id,
        booking_id,
      });
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!stripe || !clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent && paymentIntent.status) {
        case 'succeeded':
          const booking = LocalStorageManager.getItem(bookingInfo);
          setPaymentStatus(t('paymentSuccess.succeeded'));
          createPayment('Pagato', paymentIntent?.id, booking?.id);
          break;
        case 'processing':
          setPaymentStatus(t('paymentSuccess.processing'));
          break;
        case 'requires_payment_method':
          setPaymentStatus(t('paymentSuccess.requires_payment_method'));
          break;
        default:
          setPaymentStatus(t('paymentSuccess.error'));
          createPayment('Errore', paymentIntent?.id, booking?.id);
          break;
      }
    });
    return () => {
      controller.abort();
      LocalStorageManager.removeItem(bookingInfo);
    };
  }, [clientSecret, stripe]);

  return (
    <div className="text-left">
      {paymentStatus ? (
        <Alert>
          <AlertTitle>Stato Pagamento</AlertTitle>
          <AlertDescription>
            {paymentStatus}
          </AlertDescription>
        </Alert>
      ) : (
        t('general.loading')
      )}
    </div>
  );
}

export default PaymentSuccessPage;
