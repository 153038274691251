import React from 'react';
import {Button} from 'src/shadcn/components/ui/Button';
import {Input} from 'src/shadcn/components/ui/Input';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Routes from '../../routes';
import routes from '../../routes';
import {USER_ROLE_ENUM} from '../../shared/constants/constant';
import ToastService from '../../services/ToastService';
import LoadingService from 'src/components/loading/LoadingService';
import Api from 'src/shared/api/Api';
import {t} from 'i18next';
import SessionService from 'src/services/SessionService';
import {Typography} from 'src/shadcn/components/ui/Typography';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'src/shadcn/components/ui/Form';

type SignUpFormType = {
  name: string;
  last_name: string;
  address: string;
  phone_number: string;
  email: string;
  password: string;
  password_confirmation: string;
  role_id: boolean;
};

type SignUpPayload = {
  user: {
    name: string;
    last_name: string;
    address: string;
    phone_number: string;
    email: string;
    password: string;
    password_confirmation: string;
    role_id: number;
  };
};

let schema = yup.object().shape({
  name: yup.string().required(),
  last_name: yup.string().required(),
  phone_number: yup.number().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
  password_confirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  role_id: yup.boolean(),
}).required();

const SignupPage = () => {
  const form =
    useForm<SignUpFormType>({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {role_id: false},
    });

  const { handleSubmit} = form
  const navigate = useNavigate();

  const myFunc = async (value: SignUpFormType) => {
    try {
      LoadingService.show();
      const user = await Api.createResource<SignUpPayload>('users', {
        user: {
          name: value.name,
          last_name: value.last_name,
          address: value.address,
          phone_number: value.phone_number,
          email: value.email,
          password: value.password,
          password_confirmation: value.password_confirmation,
          role_id: Boolean(value.role_id)
            ? USER_ROLE_ENUM.TRAINER
            : USER_ROLE_ENUM.CLIENT,
        },
      });
      ToastService.success('Sign up effettuato con successo');
      SessionService.login(user as any);
      navigate(routes.BOOKING);
    } catch (error: any) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };


  return (
    <Form {...form}>
      <form className="grid col-1 gap-4" onSubmit={handleSubmit(myFunc)}>
        <Typography variant='h3' as='h1'>
          Registrati
        </Typography>
        <FormField
          control={form.control}
          name="name"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.name')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.name')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.last_name')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.last_name')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({field}) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Email"
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone_number"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.phone_number')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.phone_number')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.address')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.address')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.password')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.password')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password_confirmation"
          render={({field}) => (
            <FormItem>
              <FormLabel>{t('signUp.passwordConfirmation')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('signUp.passwordConfirmation')}
                  {...field}
                  type="text"
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />

        {/*<FormField*/}
        {/*  control={form.control}*/}
        {/*  name={`role_id`}*/}
        {/*  render={({ field }) => (*/}
        {/*    <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">*/}
        {/*      <FormControl>*/}
        {/*        <Checkbox*/}
        {/*          checked={field.value}*/}
        {/*          onCheckedChange={field.onChange}*/}
        {/*        />*/}
        {/*      </FormControl>*/}
        {/*      <div className="space-y-1 leading-none">*/}
        {/*        <FormLabel>*/}
        {/*          {t('signUp.trainer')}*/}
        {/*        </FormLabel>*/}
        {/*      </div>*/}
        {/*    </FormItem>*/}
        {/*  )}*/}
        {/*/>*/}

          <Button
            type="submit"
          >Registrati</Button>
          <Button
            variant="secondary"
            onClick={() => navigate(Routes.LOGIN)}
          >Accedi</Button>
      </form>
    </Form>
  );
};

export default SignupPage;
