import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import appRoutes from '../../routes';

import AdminNav from '../AdminNav';
import WeeklyAvailabilities from '../adminPages/weeklyAvailabilities/WeeklyAvailabilities';
import Events from '../adminPages/events/Events';
import Bookings from '../adminPages/bookings/Bookings';
import { t } from 'i18next';
import {Typography} from "../../shadcn/components/ui/Typography";
import EventForm from "../adminPages/events/EventForm";
import WeeklyAvailabilityForm from "../adminPages/weeklyAvailabilities/WeeklyAvailabilityForm";
import Payments from "../adminPages/payments/Payments";
import Users from "../adminPages/users/Users";
import AvailabilityEditPage from "../adminPages/weeklyAvailabilities/AvailabilityEditPage";
import AppSettings from "../adminPages/weeklyAvailabilities/AppSettings";

export const adminRoutes = [
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('app_settings'),
    altRoute: appRoutes.ADMIN,
    element: <AppSettings />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('users'),
    altRoute: appRoutes.ADMIN,
    element: <Users />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_NEW('events'),
    altRoute: appRoutes.ADMIN,
    element: <EventForm />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_VIEW('events'),
    altRoute: appRoutes.ADMIN,
    element: <EventForm />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('events'),
    altRoute: appRoutes.ADMIN,
    element: <Events />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('bookings'),
    altRoute: appRoutes.ADMIN,
    element: <Bookings />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('payments'),
    altRoute: appRoutes.ADMIN,
    element: <Payments />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_NEW('weekly_availabilities'),
    altRoute: '/',
    element: <WeeklyAvailabilityForm />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_VIEW('weekly_availabilities'),
    altRoute: appRoutes.ADMIN,
    element: <AvailabilityEditPage />,
  },
  {
    path: appRoutes.ADMIN_RESOURCE_LIST('weekly_availabilities'),
    altRoute: appRoutes.ADMIN,
    element: <WeeklyAvailabilities />,
  },
  {
    index: true,
    element: <Navigate to="/admin/bookings" replace />
  },
];


export const AdminLayout = () => {
  return <div className="grid gap-4 md:gap-10">
    <Typography variant="h3" as="h1">
      {t('admin.adminSection')}
    </Typography>
    <AdminNav/>
    <Outlet/>
  </div>
}

