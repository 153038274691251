import * as yup from "yup";


const hoursSchema = yup.array().of(
  yup.object().shape({
    id: yup.number().required(),
    event_id: yup.number().required(),
    start: yup.number().required(),
    end: yup.number()
      .required()
      .test('is-smaller', 'Fine deve essere dopo inizio', function (value) {
        if (value) {
          return value > this.parent.start;
        }

        return true
      }),
    touched: yup.boolean(),
    createdLocally: yup.boolean(),
    weekly_availability_id: yup.number().required(),
  })
)
  .test('overlapping', 'Ora overlapping', function (hours, schema) {
    function findOverlappingHours(hoursArray: any) {
      let overlappingIndices = [];

      // Iterate through all pairs to find overlaps
      for (let i = 0; i < hoursArray.length; i++) {
        for (let j = i + 1; j < hoursArray.length; j++) {

          // Check for same day_id and overlapping times
          if (
            (hoursArray[i].day_id || hoursArray[i].availability_override_id) === (hoursArray[j].day_id || hoursArray[j].availability_override_id) &&
            hoursArray[i].start < hoursArray[j].end &&
            hoursArray[i].end > hoursArray[j].start
          ) {
            overlappingIndices.push(j);
          }
        }
      }

      // Remove duplicates before returning
      return Array.from(new Set(overlappingIndices));
    }

    const hoursOvelappingIndexes = findOverlappingHours(hours)
    if (hoursOvelappingIndexes.length == 0) {
      return true
    }

    // we pick only the first overlapping hour as `test` need to return a single error, once the error is fixed we will move to the next one
    return schema.createError({path: `hours.${hoursOvelappingIndexes[0]}.start`, message: 'Overlapping hours'})
  })


export const weeklyAvailabilitySchema = yup.object().shape({
  events: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
      duration: yup.number().required(),
      increment_amount: yup.number().required(),
      trainer_id: yup.number().required(),
      trainer_email: yup.string().required(),
      price: yup.number().required(),
    })
  ),
  weeklyAvailability: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    address: yup.string().required(),
  }),
  hours: hoursSchema
}).required()


export const availOverridesSchema = yup.object().shape({
  events: yup.array(),
  availabilityOverrides: yup.array(),
  hours: hoursSchema
}).required()
