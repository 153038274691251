import React from 'react';
import { Button } from 'src/shadcn/components/ui/Button'
import { prettyFormat, toHourMinute } from 'src/shared/helpers/utils';
import { BiTime } from 'react-icons/bi';
import { BsCalendarDate } from 'react-icons/bs';
import { AiOutlineEuroCircle } from 'react-icons/ai';
import i18n from '../../../i18n';

import {BOOKING_PHASE, FormType} from '../BookingPage';
import { Typography } from 'src/shadcn/components/ui/Typography';
import {EventType} from "../../../shared/types/types";


type Props = {
  events: EventType[];
} & FormType;

const FinalizeBooking = ({ form, events }: Props) => {
  const {selectedHour, selectedDate} = form.watch()
  const event = events.find(e => e.id.toString() === form.watch('eventId'))

  return (
    <div className="flex flex-col gap-8">
        <Typography variant={'h5'} as='h2' >
          {i18n.t('finalizeBooking.confirmDatas')}
        </Typography>

      <div className={`grid grid-cols-1 gap-4`}>
        <div className='flex gap-2 items-center '>
          <BsCalendarDate/>
          <Typography className={`ml-2`}>{prettyFormat(selectedDate)}</Typography>
        </div>
        <div className='flex gap-2 items-center '>
          <BiTime/>
          <Typography className={`ml-2`}>{`${toHourMinute(
            selectedHour.start
          )}`}</Typography>
        </div>
        <div className='flex gap-2 items-center '>
          <AiOutlineEuroCircle/>
          <Typography className={`ml-2 mr-1`}>{event?.price} {i18n.t('infoBooking.valuta')}</Typography>
        </div>
      </div>
      <Button
        type='submit'
      >{i18n.t('finalizeBooking.bookButton')}
      </Button>
    </div>
  );
};

export default FinalizeBooking;
