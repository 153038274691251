import { t } from 'i18next';
import { isProduction } from './environment';


export const URL_CLIENT = isProduction
  ? 'https://osteotherapy.it'
  : 'http://localhost:3000';

export const bookingInfo = 'bookingInfo';

//sharedClasses
export const GENERAL_FONT = 'font-sans';
export const TITLE = 'text-2xl font-bold tracking-wide';
export const SUB_TITLE = 'text-xl';
// grid
export const GRID_ONE_COL = 'grid col-1 gap-4';
export enum USER_ROLE_ENUM {
  CLIENT = 1,
  TRAINER,
  ADMIN,
}
export const USER_ROLES_NAMES_MAP = new Map();
USER_ROLES_NAMES_MAP.set(USER_ROLE_ENUM.CLIENT, t('userRoles.client'));
USER_ROLES_NAMES_MAP.set(USER_ROLE_ENUM.TRAINER, t('userRoles.trainer'));
USER_ROLES_NAMES_MAP.set(USER_ROLE_ENUM.ADMIN, t('userRoles.admin'));

export const userIsAdminOrTrainer = (roleId: number) => roleId > USER_ROLE_ENUM.CLIENT;
export const userIsAdmin = (roleId: number) => roleId == USER_ROLE_ENUM.ADMIN;

export enum DAYS_ENUM {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];


const DAYS_MAP = new Map();

for (let index = 0; index < WEEK_DAYS.length; index++) {
  DAYS_MAP.set(index + 1, WEEK_DAYS[index]);
}

export{ DAYS_MAP };
