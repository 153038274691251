import React from 'react';
import i18n from 'src/i18n';

import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'src/shadcn/components/ui/Select';
import {FormType, NavigationActionsType} from '../BookingPage';
import {Button} from 'src/shadcn/components/ui/Button';
import {FormControl, FormField, FormItem, FormLabel, FormMessage} from 'src/shadcn/components/ui/Form';
import {BiTime} from "react-icons/bi";
import {FaUserTie} from "react-icons/fa";
import {GrLocationPin} from "react-icons/gr";

import {EventType} from "src/shared/types/types";
import {RadioGroup, RadioGroupItem} from 'src/shadcn/components/ui/RadioGroup';

type Props = {
  navigationActions: NavigationActionsType;
  events: EventType[];
} & FormType
const Event = ({navigationActions, events, form}: Props) => {

  const event = React.useMemo(() => {
    return events.find(e => e.id.toString() === form.watch('eventId'))
  }, [form.watch('eventId')])

  return (
    <div className="flex flex-col gap-8">
      <FormField
        control={form.control}
        name={`eventId`}
        render={({field}) => (
          <FormItem className="space-y-3">
            <FormControl>
              <RadioGroup
                onValueChange={(v) => {
                  field.onChange(v)
                  const ev = events.find(event => event.id.toString() === v)
                  form.setValue('weeklyAvailabilityId', ev?.weekly_availabilities[0]?.id?.toString() || '')
                }}
                defaultValue={field.value}
                value={field.value}
                className="flex flex-col space-y-1"
              >
                {events.map((event) => (
                    <FormItem key={event.id} className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={event.id.toString()}/>
                      </FormControl>
                      <FormLabel className="">
                        {event.name}
                      </FormLabel>
                    </FormItem>
                  )
                )}
              </RadioGroup>
            </FormControl>
            <FormMessage/>
          </FormItem>
        )}
      />
      <div className='flex gap-2 items-center '>
        <GrLocationPin/>
        {event && event?.weekly_availabilities.length <= 1 && (
          <FormLabel>{event.weekly_availabilities[0].address}</FormLabel>
        )}
        {event && event?.weekly_availabilities.length > 1 && (
          <FormField
            control={form.control}
            name={`weeklyAvailabilityId`}
            render={({field}) => {
              return (
                <FormItem>
                  <FormControl>
                    <Select onValueChange={(e) => e && field.onChange(e)} value={field.value?.toString()}>
                      <SelectTrigger>
                        <SelectValue placeholder="Indirizzo"/>
                      </SelectTrigger>
                      <SelectContent>
                        {event?.weekly_availabilities?.map((v) => (
                          <SelectItem key={v.id} value={v.id.toString()}>
                            {v.address}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage/>
                </FormItem>
              )
            }}
          />
        )}
      </div>

      {/*Durata*/}
      <div className='flex gap-2 items-center '>
        <BiTime/>
        <FormLabel>
          {i18n.t('event.eventTime', {
            duration: event?.duration,
            timeUnit: 'Minuti',
          })}
        </FormLabel>
      </div>

      {/*Osteopata*/}
      <div className='flex gap-2 items-center '>
        <FaUserTie/>
        <FormLabel>
          {event?.trainer_name ||
            event?.trainer_email
          }
        </FormLabel>
      </div>

      <Button
        disabled={!event}
        className={'mt-8'}
        onClick={navigationActions.navigateForward}
        type={'button'}
      >Continua</Button>
    </div>
  );
};

export default Event;
