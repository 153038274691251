import LoadingService from "../../../components/loading/LoadingService";
import Api from "../../../shared/api/Api";
import {HourType} from "../../../shared/types/types";
import ToastService from "../../../services/ToastService";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import {WeeklyAvailabilityFormType} from "./useWeeklyAvailabilityForm";
import {AvailOverrideFormType} from "./useAvailabilityOverride";

export const useHours = (form: UseFormReturn<WeeklyAvailabilityFormType | AvailOverrideFormType> ) => {
  const hoursController = useFieldArray({
    control: form.control,
    name: "hours"
  });
  const removeHour = async (index: number, availId?: number, isOverride: boolean = false) => {
    try {
      LoadingService.show();
      const hour = form.getValues('hours')[index]
      if (!hour) return
      if (hour.createdLocally) {
        hoursController.remove(index)
      } else {
        await Api.deleteResource('hours', hour.id);
        const params = `weekly_availability_id=${availId}${isOverride ? '&with_availability_overrides=true' : ''}`;
        const hours = await Api.getResources<HourType[]>('hours', params)
        form.setValue("hours", hours)
      }
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  }
  const appendNewHour = ({dayId, availOverrideId, weeklyAvailId}: {
    dayId?: number,
    availOverrideId?: number,
    weeklyAvailId?: number
  }) => {
    const hrs = form.getValues('hours') as HourType[]
    const selectedHours = hrs.filter(h => {
      if (availOverrideId) {
        return h.availability_override_id === availOverrideId
      } else {
        return h.day_id === dayId
      }
    })
    const lastHour = selectedHours[selectedHours.length - 1]
    hoursController.append({
      id: Math.random(),
      day_id: dayId,
      start: lastHour ? Number(lastHour.end) : 450,
      end: lastHour ? Number(lastHour.end) + 60 : 510,
      weekly_availability_id: weeklyAvailId as number,
      availability_override_id: availOverrideId,
      createdLocally: true,
      touched: true
    })
  }

  return {
    hoursController,
    removeHour,
    appendNewHour
  }
}


