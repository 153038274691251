import React, {useEffect} from 'react';
import {Cross1Icon, PlusIcon} from "@radix-ui/react-icons";
import {t} from 'i18next';
import {
  unstable_useBlocker as useBlocker,
  useParams
} from "react-router";

import {Input} from 'src/shadcn/components/ui/Input';
import {Button} from 'src/shadcn/components/ui/Button';
import {Label} from 'src/shadcn/components/ui/Label';
import SelectHour from 'src/components/SelectHour';
import SelectEvent from 'src/components/SelectEvent';
import {DAYS_ENUM, WEEK_DAYS} from 'src/shared/constants/constant';
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/shadcn/components/ui/Card";
import {
  Form,
  FormItem,
  FormControl,
  FormMessage,
  FormField,
} from 'src/shadcn/components/ui/Form';

import {useWeeklyAvailabilityForm} from "./useWeeklyAvailabilityForm";
import ConfirmAlert, {useConfirmAlert} from "../../../components/ConfirmAlert";
import ToastService from '../../../services/ToastService';
import {useNavigate} from "react-router-dom";
import routes from "../../../routes";

function generateLightColor() {
  return `#${Math.floor(Math.random() * 56 + 200).toString(16)}${Math.floor(Math.random() * 56 + 200).toString(16)}${Math.floor(Math.random() * 56 + 200).toString(16)}`;
}

const WeeklyAvailabilityForm = () => {
  const {id: paramId} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {hide, toggleVisibility} = useConfirmAlert()
  const {
    form,
    hours,
    appendNewHour,
    removeHour,
    onCreateSubmit,
    onEditSubmit,
  } = useWeeklyAvailabilityForm();
  const formDirty = form.formState.isDirty;
  const blocker = useBlocker(formDirty);

  const isEdit = !!paramId

  const onSubmit = isEdit ? onEditSubmit : onCreateSubmit
  const onError = (errors: any) => {
    ToastService.error(errors)
  }

  React.useEffect(() => {
    if (blocker.state === 'blocked' && !formDirty) {
      blocker.reset();
    }
  }, [blocker, formDirty]);


  React.useEffect(() => {
    if(form.formState.isSubmitSuccessful) {
      navigate(`/admin/${routes.ADMIN_RESOURCE_LIST('weekly_availabilities')}`);
    }
  }, [form.formState.isSubmitSuccessful])

  const events = form.watch('events')
  const eventsColors = React.useMemo(function generateEventsColor() {
    return events.map(ev => ({id: ev.id, color: generateLightColor()}))
  }, [events])

  return (
    <>
      <ConfirmAlert
        onChange={toggleVisibility}
        open={blocker.state === 'blocked'}
        onCancel={() => {
          blocker.reset?.()
          hide()
        }}
        onConfirm={() => {
          blocker.proceed?.()
          hide()
        }}
        confirmButtonLabel={'Procedi'}
        alertTitle={'Se procedi perderai le modifiche effettuate. Procedere?'}
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onError)}>
          <Card>
            <CardHeader>
              <CardTitle className='text-xl'>
                <>{t('admin.weeklyAvailabilityForm.setWeeklyAvailabilities')}</>
              </CardTitle>
            </CardHeader>
            <CardContent className="grid gap-8">
              <div className="grid w-full items-center gap-1.5">
                <FormField
                  control={form.control}
                  name={`weeklyAvailability.name`}
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          type="text"
                          placeholder='Name'
                        />
                      </FormControl>
                      <FormMessage/>
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid w-full items-center gap-1.5">
                <FormField
                  control={form.control}
                  name={`weeklyAvailability.address`}
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          type="text"
                          placeholder='Indirizzo'
                        />
                      </FormControl>
                      <FormMessage/>
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
            <CardHeader>
              <CardDescription>Orari settimanali di default</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-8">
              {/*days mapping*/}
              {WEEK_DAYS.map((day: string) => {
                  // @ts-ignore
                  const dayId = DAYS_ENUM[day]
                  // find hours for this day
                  const hoursOfTheDay = hours.filter((h) => h.day_id === dayId)
                  return (
                    <div key={day}>
                      <Label className="col-span-4">{t(`days.${day}`)}</Label>

                      {/*hours mapping*/}
                      {hoursOfTheDay?.map((hour) => {
                          const index = hours.findIndex((h) => h.id === hour.id)
                          return (
                            <div
                              key={hour.id}
                              className="grid grid-cols-4 gap-2 py-4">
                              <div className="col-span-3">
                                {/*event*/}
                                <FormField
                                  control={form.control}
                                  name={`hours.${index}.event_id`}
                                  render={({field}) => (
                                    <FormItem key={hour.id}>
                                      <FormControl>
                                        <SelectEvent
                                          events={events}
                                          value={field.value}
                                          selectTriggerStyles={{backgroundColor: eventsColors.find(ev => ev.id === field.value)?.color}}
                                          onChange={(e: string) => {
                                            form.setValue(`hours.${index}.event_id`, Number(e))
                                            form.setValue(`hours.${index}.touched`, true)
                                          }}
                                        />
                                      </FormControl>
                                      <FormMessage/>
                                    </FormItem>
                                  )}
                                />
                              </div>
                              <Button type='button' className='col-span-1' onClick={() => removeHour(index, hour.weekly_availability_id)}
                                      variant="destructive">
                                <Cross1Icon/>
                              </Button>
                              <div className="col-span-2">
                                {/*start hour*/}
                                <FormField
                                  control={form.control}
                                  name={`hours.${index}.start`}
                                  render={({field}) => (
                                    <FormItem key={hour.id}>
                                      <FormControl>
                                        <SelectHour
                                          {...field}
                                          onChange={(e) => {
                                            form.setValue(`hours.${index}.start`, Number(e))
                                            form.setValue(`hours.${index}.touched`, true)
                                          }}
                                        />
                                      </FormControl>
                                      <FormMessage/>
                                    </FormItem>
                                  )}
                                />
                              </div>
                              <div className="col-span-2">
                                {/*end hour*/}
                                <FormField
                                  control={form.control}
                                  name={`hours.${index}.end`}
                                  render={({field}) => (
                                    <FormItem key={hour.id}>
                                      <SelectHour
                                        value={field.value}
                                        onChange={(e) => {
                                          form.setValue(`hours.${index}.end`, Number(e))
                                          form.setValue(`hours.${index}.touched`, true)
                                        }}
                                      />
                                      <FormMessage/>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>
                          )
                        }
                      )}
                      <Button className="col-span-4" type='button' onClick={() => appendNewHour({dayId, weeklyAvailId: form.getValues('weeklyAvailability.id')})}
                              variant="secondary">
                        <PlusIcon type='button'/>
                      </Button>
                    </div>
                  )
                }
              )}
            </CardContent>
            <CardFooter>
              <Button
                type="submit"
                disabled={form.formState.isSubmitSuccessful}
              >{t('admin.weeklyAvailabilityForm.submitButton')}</Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </>
  );
};


export default WeeklyAvailabilityForm;
