import React, {useEffect} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import Api from "../shared/api/Api";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppMaintanenceAlert} from "./AppMaintanenceAlert";

const schema = yup.object().shape({
  in_maintenance: yup.boolean()
}).required()

export type AppSettingsFormType = {
  id?: number,
  in_maintenance: boolean,
}
const useAppSettings = () => {
  const form = useForm<AppSettingsFormType>({
      resolver: yupResolver(schema),
      defaultValues: {
        in_maintenance: false
      }
    }
  )

  return {
    form
  }
}
export const AppSettingsContext = React.createContext<{
  form: UseFormReturn<AppSettingsFormType, any, undefined>
}>({form: {}} as any)
export const AppSettingsProvider = ({children}: any) => {
  const {form} = useAppSettings()
  useEffect(function fetchAppSettings() {
    const asyncFetch = async () => {
      const response = await Api.getResources<AppSettingsFormType>('app_settings')
      form.setValue('in_maintenance', response?.in_maintenance)
      form.setValue('id', response?.id)
    }
    asyncFetch()
  }, [])

  return (
    <AppSettingsContext.Provider value={{form}}>
      <AppMaintanenceAlert inMaintenance={form.watch('in_maintenance')}/>
      {children}
    </AppSettingsContext.Provider>
  )

}
