import React, {useEffect} from 'react';
import LoadingService from 'src/components/loading/LoadingService';
import ToastService from 'src/services/ToastService';
import Api from 'src/shared/api/Api';
import * as yup from 'yup';
import {
  EventType, FormExtraProps,
  hourToPayload,
  HourType,
  WeeklyAvailabiliyType,
} from 'src/shared/types/types';
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {weeklyAvailabilitySchema} from "./schemas";
import {useHours} from "./formUtils";

export type WeeklyAvailabilityFormType = {
  events: EventType[];
  weeklyAvailability: WeeklyAvailabiliyType
  hours: (HourType & FormExtraProps)[];
}
export function useWeeklyAvailabilityForm() {
  const {id} = useParams<{ id: string }>();

  const form = useForm<WeeklyAvailabilityFormType>({
    resolver: yupResolver(weeklyAvailabilitySchema),
    mode: 'onSubmit',
    defaultValues: {
      events: [],
      weeklyAvailability: {
        name: '',
        id: 0,
        address: '',
      },
      hours: [],
    }
  });
  // @ts-ignore
  const {hoursController, appendNewHour, removeHour} = useHours(form)

  // fetch events
  useEffect(() => {
    const controller = new AbortController();
    const fetchEvents = async () => {
      try {
        LoadingService.show();
        const response = await Api.getResources<{data: EventType[]}>('events')
        form.setValue('events', response.data);
        return response
      } catch (error) {
        ToastService.error(error);
      } finally {
        LoadingService.hide();
      }
    }
    fetchEvents()
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(function fetchAvailAndHours() {
    if (!id) return
    const controller = new AbortController();
    const updateFormValues = async () => {
      const avail = await Api.getResource<WeeklyAvailabiliyType>('weekly_availabilities', Number(id))
      const hours = await Api.getResources<HourType[]>('hours', `weekly_availability_id=${avail.id}`)
      form.setValue("weeklyAvailability", avail)
      form.setValue("hours", hours)
    }

    updateFormValues()
    return () => {
      controller.abort();
    };
  }, [id])

  const onCreateSubmit = async (data: {
    weeklyAvailability: WeeklyAvailabiliyType,
    hours: (HourType & FormExtraProps)[]
  }) => {
    try {
      LoadingService.show();

      const response = await Api.createResource<WeeklyAvailabiliyType>('weekly_availabilities', {
        name: data.weeklyAvailability.name,
        address: data.weeklyAvailability.address,
      });

      const arrayPromises = (data.hours || [])
        .filter(h => h?.touched)
        .map(h => {
          return Api.createResource('hours', {...hourToPayload(h), weekly_availability_id: response.id});
        });

      const hours = await Promise.all(arrayPromises) as HourType[];
      const avail = await Api.getResource<WeeklyAvailabiliyType>('weekly_availabilities', response.id)

      form.reset({...data, hours: hours, weeklyAvailability: avail})
      ToastService.success('Creato');
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  const onEditSubmit = async (data: {
    weeklyAvailability: WeeklyAvailabiliyType,
    hours: (HourType & FormExtraProps)[]
  }) => {
    try {
      if (!id) return;
      LoadingService.show();

      const arrayPromises = (data.hours || [])
        .filter(h => h?.touched)
        .map(h => {
          return h.createdLocally ?
            Api.createResource('hours', {...hourToPayload(h), weekly_availability_id: Number(id)}) :
            Api.editResource('hours', h.id, {...hourToPayload(h), weekly_availability_id: Number(id)})
        });

      await Promise.all(arrayPromises);

      const hours = await Api.getResources<HourType[]>('hours', `weekly_availability_id=${id}`)

      if (form.formState.touchedFields.weeklyAvailability) {
        const weeklyAvailabiliy = await Api.editResource<WeeklyAvailabiliyType>('weekly_availabilities', id, {
          name: data.weeklyAvailability.name,
          address: data.weeklyAvailability.address,
        });
        form.reset({...data, hours: hours, weeklyAvailability: weeklyAvailabiliy})
      } else {
        form.reset({...data, hours: hours})
      }


      ToastService.success('Modificato!');
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };

  return {
    onCreateSubmit,
    onEditSubmit,
    form,
    hours: hoursController.fields,
    appendNewHour,
    removeHour
  }
}
