import {
  AlertDialog, AlertDialogAction, AlertDialogCancel,
  AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
  AlertDialogHeader, AlertDialogTitle,
  AlertDialogTrigger
} from "../shadcn/components/ui/AlertDialog";
import React from "react";
import {buttonVariants} from "../shadcn/components/ui/Button";
import {cn} from "src/shadcn/lib/utils";

export const useConfirmAlert = () => {
  const [open, setOpen] = React.useState(false)
  const toggleVisibility = () => setOpen(!open)
  const hide = () => setOpen(false)
  const show = () => setOpen(true)

  return {open, toggleVisibility, hide, show}
}
const ConfirmAlert = (
  {
    open,
    onChange,
    onConfirm,
    onCancel,
    alertTrigger,
    alertTitle,
    alertDescription,
    confirmButtonLabel,
    cancelButtonLabel,
    onConfirmSecondayAction,
    secondaryActionLabel
  }: any) => {
  return (
    <AlertDialog open={open} onOpenChange={onChange}>
      <AlertDialogTrigger>{alertTrigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{alertTitle}</AlertDialogTitle>
          <AlertDialogDescription>
            {alertDescription}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {onCancel && <AlertDialogCancel onClick={onCancel}>{cancelButtonLabel || 'Indietro'}</AlertDialogCancel>}
          {secondaryActionLabel && <AlertDialogAction className={cn(buttonVariants({variant: 'secondary'}))}
                                                      onClick={onConfirmSecondayAction}>{secondaryActionLabel}</AlertDialogAction>}
          <AlertDialogAction type={'submit'} onClick={onConfirm}>{confirmButtonLabel || 'Continua'}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>

  )
}

export default ConfirmAlert