import React from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import routes from '../../routes';
import LoadingService from '../../components/loading/LoadingService';
import ToastService from 'src/services/ToastService';
import { bookingInfo, URL_CLIENT } from 'src/shared/constants/constant';
import LocalStorageManager from '../../services/StorageService';
import {Button} from 'src/shadcn/components/ui/Button';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      LoadingService.show();
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${URL_CLIENT}${routes.PAYMENT_SUCCESS}`,
        },
      });
      if (error.type === 'card_error' || error.type === 'validation_error') {
        error.message && ToastService.error(error.message);
      } else {
        ToastService.error("Qualcosa e' andato storto");
      }
    } catch (error) {
      ToastService.error(error);
    } finally {
      LoadingService.hide();
    }
  };
  const booking = LocalStorageManager.getItem(bookingInfo);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        id="submit"
        type={'submit'}
        disabled={!stripe || !elements}
      >
        <span id="button-text">Paga - {booking.price} Euro </span>
      </Button>
    </form>
  );
}
