import React from 'react';
import {Typography} from "../shadcn/components/ui/Typography";
import HttpService from "../services/HttpService";
import ToastService from "../services/ToastService";

const ErrorPage = () => {
  return (
      <div className="min-h-screen flex items-center justify-center bg-secondary">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-3xl font-bold text-destructive mb-4">Oops! Qualcosa e' andato storto.</h1>
          <Typography>Ci scusiamo per il disagio, il nostro team sta lavorando per sistemarlo.</Typography>
        </div>
      </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Send error details to your server
    this.sendErrorToServer(error, errorInfo);
  }

  sendErrorToServer(error: any, errorInfo: any) {
    const payload = {
      error: error.toString(),
      error_info: errorInfo.componentStack,
    }
    HttpService.post(`/client_logs`, payload).catch((err) => {
     ToastService.error("Errore durante l'invio del report di errore");
    });
  }

  render() {
    //@ts-expect-error
    if (this.state.hasError) {
      sessionStorage.clear();
      // You can render any custom fallback UI
      return <ErrorPage/>
    }

    //@ts-expect-error
    return this.props.children;
  }
}

export default ErrorBoundary;
