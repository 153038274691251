import React from 'react';
import { EventType } from 'src/shared/types/types';
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'src/shadcn/components/ui/Select';


type Props = {
  value?: string | number
  onChange?: (e: any) => void;
  events?: EventType[];
  selectTriggerStyles?: any;
}

export const SelectEvent = React.forwardRef((props: Props, ref: any) => {
  const {events, onChange, value,  selectTriggerStyles} = props
  return (
    <Select value={value?.toString()} onValueChange={props.onChange} >
      <SelectTrigger ref={ref} style={selectTriggerStyles}>
        <SelectValue placeholder= {"Seleziona evento"} />
      </SelectTrigger>
      <SelectContent position={'item-aligned'} >
        {events?.map((ev: EventType) => (
          <SelectItem key={ev.name} value={ev.id?.toString() || ''}>
            {ev.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

export default SelectEvent;
