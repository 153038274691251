import React from "react";
import {useParams} from "react-router-dom";
import {CalendarIcon} from "lucide-react";
import {Button} from "src/shadcn/components/ui/Button";
import {Calendar} from "src/shadcn/components/ui/Calendar";
import {format} from "date-fns";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from "src/shadcn/components/ui/Form";
import SelectHour from "src/components/SelectHour";
import {Cross1Icon} from "@radix-ui/react-icons";
import {SelectEvent} from "src/components/SelectEvent";
import {DateTime} from "luxon";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/components/ui/Dialog"
import {PopoverContentDialog, PopoverDialog, PopoverTriggerDialog} from "src/shadcn/components/ui/PopoverDialog";
import {Typography} from "src/shadcn/components/ui/Typography";
import {convertMinutesToHoursMinutes} from "src/shared/helpers/utils";
import {cn} from "src/shadcn/lib/utils";
import {useAvailabilityOverride} from "./useAvailabilityOverride";


const AvailabilityOverride = () => {
  const {id: weeklyAvailabilityId} = useParams<{ id: string }>();
  const {
    form,
    defaultAvailOverride,
    appendNewHour,
    removeHour,
    removeAvailOverride,
    onSubmit
  } = useAvailabilityOverride()
  const availabilityOverrides = form.watch('availabilityOverrides')
  const [hours, events, availSelected] = form.watch(['hours', 'events', 'currentAvailOverrides'])
  const [open, setOpen] = React.useState(false);

  const selectedHours = React.useMemo(() => {
    return hours.filter(h => h.availability_override_id === availSelected?.id)
  }, [availSelected?.id, hours])

  const closeDialog = () => {
    setOpen(false)
  }

  if (!weeklyAvailabilityId) return null
  return (
    <div>
      <Button variant='secondary' onClick={() => {
        form.setValue('currentAvailOverrides', defaultAvailOverride)
        setOpen(true);
      }}>Aggiungi data</Button>
      <Dialog open={open}>
        <DialogContent className="sm:max-w-md overflow-y-scroll max-h-screen">
          <Form {...form}>
            <form onSubmit={form.handleSubmit((data) => onSubmit(data, closeDialog))} className="space-y-8">
              <DialogHeader>
                <DialogTitle>Orari particolari</DialogTitle>
                <DialogDescription>
                  Seleziona uno o piu' giorni e aggiungi orari particolari
                </DialogDescription>
              </DialogHeader>
              <div className='flex gap-2'>
                <FormField
                  control={form.control}
                  name={`currentAvailOverrides.date`}
                  render={({field}) => (
                    <FormItem className="w-full flex flex-col">
                      <PopoverDialog>
                        <PopoverTriggerDialog>
                          <FormControl>
                            <Button
                              id="date"
                              variant={"outline"}
                              className={cn(
                                "justify-start text-left font-normal",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value?.from ? (
                                field.value.to ? (
                                  <>
                                    {format(field.value.from, "LLL dd, y")} -{" "}
                                    {format(field.value.to, "LLL dd, y")}
                                  </>
                                ) : (
                                  format(field.value.from, "LLL dd, y")
                                )
                              ) : (
                                <span>Pick a date</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50"/>
                            </Button>
                          </FormControl>
                        </PopoverTriggerDialog>
                        <PopoverContentDialog className="w-auto p-0 " align="start">
                          <Calendar
                            initialFocus
                            mode="range"
                            selected={field.value}
                            onSelect={(value) => {
                              form.setValue(`currentAvailOverrides.touched`, true)
                              // if value is undefined we must send null to make the field empty
                              field.onChange(value || null)
                            }}
                            numberOfMonths={1}
                          />
                        </PopoverContentDialog>
                      </PopoverDialog>
                      <FormMessage/>
                    </FormItem>
                  )}
                />
                <Button variant='secondary' size='sm'
                        onClick={() => availSelected?.id && appendNewHour({
                          dayId: undefined,
                          availOverrideId: availSelected.id,
                          weeklyAvailId: Number(weeklyAvailabilityId)
                        })}>
                  +
                </Button>
              </div>
              {selectedHours.map((hour) => {
                  const index = hours.findIndex(h => h.id === hour.id)
                  if (index === -1) return null
                  return (
                    <div
                      key={hour.id * 1000}
                      className="grid grid-cols-4 gap-2 py-4">
                      <div className="col-span-3">
                        {/*event*/}
                        <FormField
                          control={form.control}
                          name={`hours.${index}.event_id`}
                          render={({field}) => (
                            <FormItem key={hour.id}>
                              <FormControl>
                                <SelectEvent
                                  events={events}
                                  value={field.value}
                                  onChange={(e: string) => {
                                    form.setValue(`hours.${index}.event_id`, Number(e))
                                    form.setValue(`hours.${index}.touched`, true)
                                  }}
                                />
                              </FormControl>
                              <FormMessage/>
                            </FormItem>
                          )}
                        />
                      </div>
                      <Button
                        type='button'
                        className='col-span-1'
                        onClick={() => removeHour(index, Number(weeklyAvailabilityId), true)}
                        variant="destructive">
                        <Cross1Icon/>
                      </Button>
                      <div className="col-span-2">
                        {/*start hour*/}
                        <FormField
                          control={form.control}
                          name={`hours.${index}.start`}
                          render={({field}) => (
                            <FormItem key={hour.id}>
                              <FormControl>
                                <SelectHour
                                  {...field}
                                  onChange={(e) => {
                                    form.setValue(`hours.${index}.start`, Number(e))
                                    form.setValue(`hours.${index}.touched`, true)
                                  }}
                                />
                              </FormControl>
                              <FormMessage/>
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="col-span-2">
                        {/*end hour*/}
                        <FormField
                          control={form.control}
                          name={`hours.${index}.end`}
                          render={({field}) => (
                            <FormItem key={hour.id}>
                              <SelectHour
                                value={field.value}
                                onChange={(e) => {
                                  form.setValue(`hours.${index}.end`, Number(e))
                                  form.setValue(`hours.${index}.touched`, true)
                                }}
                              />
                              <FormMessage/>
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  )
                }
              )}
              <DialogFooter className="sm:justify-start">
                <DialogClose asChild>
                  <Button onClick={() => {
                    setOpen(false)
                  }} type="button" variant="secondary">
                    Cancella
                  </Button>
                </DialogClose>
                <Button type="submit">Salva</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      {availabilityOverrides.map((availOverride, index: number) => {
        return (
          <div key={availOverride.id}>
            <div className='flex mt-4 gap-2'>
              <Button className='flex gap-2 justify-around' onClick={() => {
                form.setValue(`currentAvailOverrides.id`, availOverride.id)
                form.setValue(`currentAvailOverrides.date`, availOverride.date)
                form.setValue(`currentAvailOverrides.weekly_availability_id`, availOverride.weekly_availability_id)
                form.setValue(`currentAvailOverrides.createdLocally`, false)
                setOpen(true);
              }} variant='outline'>
                <Typography>{
                  availOverride.date?.from && availOverride.date?.to && `${DateTime.fromJSDate(availOverride.date?.from).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} - ${DateTime.fromJSDate(availOverride.date?.to).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`
                }</Typography>
                <div>
                {hours.filter(h => h.availability_override_id === availOverride.id).map((hour) => {
                  return <div className="border-2-transparent mt-2 bg-secondary rounded-lg p-2" key={hour.id} >
                    {convertMinutesToHoursMinutes(hour.start)}-{convertMinutesToHoursMinutes(hour.end)}
                  </div>
                })}</div>
              </Button>
              <Button type='button' className='flex-1 p-4 text-destructive'  onClick={() => removeAvailOverride(availOverride.id)} variant="ghost">
                <Cross1Icon/>
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  );
}
export default AvailabilityOverride;