import React, {useContext, useEffect, useRef} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import routes from '../routes';
import i18n from '../i18n';
import 'react-toastify/dist/ReactToastify.css';
import {AiOutlineCalendar, AiOutlineUser} from 'react-icons/ai';
import {GrUserAdmin} from 'react-icons/gr';
import {BiLogIn, BiLogOut} from 'react-icons/bi';
import SessionService from '../services/SessionService';
import {AuthContext} from '../authentication/AuthContext';
import AuthApi from 'src/pages/auth/AuthApi/LoginService';
import {userIsAdminOrTrainer} from 'src/shared/constants/constant';
import ToastService from 'src/services/ToastService';
import {RxHamburgerMenu} from 'react-icons/rx';
import {ModeToggle} from './ToogleTheme';

function useOutsideAlerter(ref: any, fn: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(false);
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [fn, ref]);
}

function OutsideAlerter(props: any) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.setNavbarOpen);

  return <div ref={wrapperRef}>{props.children}</div>;
}

export default function Navbar({fixed}: { fixed?: any }) {
  const navigate = useNavigate();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const {user} = useContext(AuthContext);
  const {pathname} = useLocation();

  const onLogOut = async () => {
    try {
      await AuthApi.logout();
      navigate(routes.LOGIN);
      SessionService.logOut();
      ToastService.success(i18n.t('toastMessages.other.logOut'));
    } catch (error) {
    }
  };

  return (
    <OutsideAlerter setNavbarOpen={setNavbarOpen}>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 md:shadow-md">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex items-center gap-2">
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <RxHamburgerMenu/>
              </button>
              <div className="lg:hidden">
                <ModeToggle/>
              </div>
            </div>
            <Link
              to={routes.HOME}
              className="text-m font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
            >
              <span className="text-popover-foreground">OS-</span>
              <span className="text-primary">TEO</span>
              <span className="text-popover-foreground">-THERAPY</span>
            </Link>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center' +
              (navbarOpen ? ' flex' : ' hidden')
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <Link
                  to={routes.BOOKING}
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug"
                >
                  <div
                    className={`flex border-b-2 ${
                      pathname === routes.BOOKING
                        ? 'border-primary'
                        : 'border-transparent'
                    }`}
                  >
                    <AiOutlineCalendar className="md:hidden mr-2"/>
                    <div>{i18n.t('nav.bookings')}</div>
                  </div>
                </Link>
              </li>
              {!user && (
                <li className="nav-item">
                  <Link
                    to={routes.LOGIN}
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  "
                  >
                    <div
                      className={`flex border-b-2 ${
                        pathname === routes.LOGIN
                          ? 'border-primary'
                          : 'border-transparent'
                      }`}
                    >
                      <BiLogIn className="md:hidden mr-2"/>
                      <div>{i18n.t('nav.logIn')}</div>
                    </div>
                  </Link>
                </li>
              )}
              {/*show this only to user and not admin, make just confusion*/}
              {user && !userIsAdminOrTrainer(user.role_id) && (
                <li className="nav-item">
                  <Link
                    to={routes.USER}
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  "
                  >
                    <div
                      className={`flex border-b-2 ${
                        pathname === routes.USER
                          ? 'border-primary'
                          : 'border-transparent'
                      }`}
                    >
                      <AiOutlineUser className="md:hidden mr-2"/>
                      <div>{i18n.t('nav.user')}</div>
                    </div>
                  </Link>
                </li>
              )}
              {user && userIsAdminOrTrainer(user.role_id) && (
                <li className="nav-item">
                  <Link
                    to={routes.ADMIN}
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug "
                  >
                    <div
                      className={`flex border-b-2 ${
                        pathname.includes(routes.ADMIN)
                          ? 'border-primary'
                          : 'border-transparent'
                      }`}
                    >
                      <GrUserAdmin className="md:hidden mr-2"/>
                      <div>{i18n.t('nav.admin')}</div>
                    </div>
                  </Link>
                </li>
              )}
              {user && (
                <li className="nav-item">
                  <div className=" cursor-pointer">
                    <div
                      onClick={() => {
                        onLogOut();
                      }}
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75"
                    >
                      <div className="flex border-b-2 border-transparent hover:border-primary">
                        <BiLogOut className="md:hidden mr-2"/>
                        <div>{i18n.t('nav.logOut')}</div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
              <div className="hidden lg:block">
                <ModeToggle/>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </OutsideAlerter>
  );
}
