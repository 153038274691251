import React, {useContext, useEffect, useState} from 'react';
import {Form} from 'src/shadcn/components/ui/Form';
import {ChevronLeftIcon} from "@radix-ui/react-icons"
import Calendar from './components/BookingCaledar';
import Availabilities from './components/Availabilities';
import Event from './components/Event';

import FinalizeBooking from './components/FinalizeBooking';
import {t} from 'i18next';
import {Progress} from 'src/shadcn/components/ui/Progress';
import {Button} from 'src/shadcn/components/ui/Button';
import {useForm, UseFormReturn} from "react-hook-form";
import {BookingType, EventType, TimeRangeType} from "../../shared/types/types";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../authentication/AuthContext";
import LoadingService from "../../components/loading/LoadingService";
import routes from "../../routes";
import {toDateShort,  toHourMinute} from "../../shared/helpers/utils";
import Api from "../../shared/api/Api";
import LocalStorageManager from "../../services/StorageService";
import {bookingInfo, userIsAdminOrTrainer} from "../../shared/constants/constant";
import ToastService from "../../services/ToastService";
import {Typography} from 'src/shadcn/components/ui/Typography';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import ConfirmAlert, {useConfirmAlert} from "../../components/ConfirmAlert";
import {RadioGroup, RadioGroupItem} from "../../shadcn/components/ui/RadioGroup";
import {Label} from 'src/shadcn/components/ui/Label';

export enum BOOKING_PHASE {
  'EVENT_INFO',
  'CALENDAR',
  'AVAILABILITY',
  'CONFIRM',
  'SUCCESS',
}


export type NavigationActionsType = {
  navigateForward: () => void;
  navigateBack: () => void;
};

type FormBaseType = {
  selectedDate: string,
  selectedHour: { start: string, end: string },
  eventId: string,
  slots: TimeRangeType[],
  recurrent: boolean,
  weeklyAvailabilityId: string,
  clientId: string,
}
export type FormType = {
  form: UseFormReturn<FormBaseType, any, undefined>
}

const today = new Date();
today.setHours(0, 0, 0, 0);

function BookingPage() {
  const [currentBookingPhase, setBookingPhase] = useState<BOOKING_PHASE>(
    BOOKING_PHASE.EVENT_INFO
  );
  const [paymentType, setPaymentType] = useState('withCard')
  const [events, setEvents] = useState<EventType[]>([]);

  useEffect(() => {
    const controller = new AbortController();
    const asyncFn = async () => {
      const response = await Api.getResources<{data: EventType[]}>(
        'events',
        'with_avail=true'
      );
      setEvents(response.data);
    };
    asyncFn();
    return () => {
      controller.abort();
    };
  }, []);

  const schema = yup.object().shape({
    selectedDate: yup.string().required(),
    selectedHour: yup.object().shape({
      start: yup.string().required(),
      end: yup.string().required(),
    }),
    eventId: yup.string().required(),
    slots: yup.array().of(
      yup.object().shape({
        start: yup.string().required(),
        end: yup.string().required(),
      })
    ),
    recurrent: yup.boolean(),
    weeklyAvailabilityId: yup.string().required(),
    clientId: yup.string(),
  }).required()

  const form = useForm<FormBaseType>({
      resolver: yupResolver(schema),
      defaultValues: {
        selectedDate: today.toISOString(),
        selectedHour: {start: '', end: ''},
        eventId: undefined,
        slots: [],
        recurrent: false,
        weeklyAvailabilityId: undefined,
        clientId: undefined,
      }
    }
  )

  // set initial event and weekly availability
  React.useEffect(() => {
    if (events[0]) {
      form.setValue('eventId', events[0]?.id?.toString())
      form.setValue('weeklyAvailabilityId', events[0].weekly_availabilities[0]?.id.toString())
    }
  }, [events])

  const navigateForward = () => {
    if (currentBookingPhase === BOOKING_PHASE.SUCCESS) return;
    setBookingPhase((prev) => prev + 1);
  };

  const navigateBack = () => {
    if (currentBookingPhase === BOOKING_PHASE.EVENT_INFO) return;
    setBookingPhase((prev) => prev - 1);
  };

  const navigationActions = {
    navigateForward,
    navigateBack,
  };

  const navigate = useNavigate();
  const {user} = useContext(AuthContext);
  const {clientId, recurrent, selectedHour, eventId, weeklyAvailabilityId, selectedDate} = form.watch()
  const {hide, show, open, toggleVisibility} = useConfirmAlert()
  const {
    hide: hideSuccessAlert,
    show: showSuccessAlert,
    open: openSuccessAlert,
    toggleVisibility: tooggleVisibilitySuccessAlert
  } = useConfirmAlert()

  const event = events.find(e => e.id.toString() === form.watch('eventId'))
  const createBooking = async () => {
    try {
      LoadingService.show();
      const booking = await Api.createResource<BookingType>('bookings', {
        // remember the browser is converting the time to Coordinated Universal Time (UTC), which is represented by the 'Z' at the end of the string
        start: selectedHour.start,
        end: selectedHour.end,
        recurrent: recurrent,
        trainer_id: event?.trainer_id,
        event_id: event?.id,
        user_id: clientId || user?.id,
        weekly_availability_id: weeklyAvailabilityId,
        payment_status: 'Non pagato',
      });
      LocalStorageManager.setItem(bookingInfo, {
        ...booking,
        price: event?.price,
      });
      showSuccessAlert()
    } catch (e: any) {
      ToastService.error(e);
    } finally {
      LoadingService.hide();
    }
  }
  const onSubmit = async () => {
    if (!eventId) {
      throw new Error('Event missing');
    }
    if (!user) {
      navigate(routes.LOGIN);
      throw new Error(t('finalizeBooking.loginNotDone'));
    }
    recurrent ? show() : await createBooking()
  }
  return (
    <>
      <ConfirmAlert
        onChange={toggleVisibility}
        open={open}
        onCancel={hide}
        onConfirm={async () => {
          await createBooking();
          hide()
        }}
        alertTitle={'Stai per creare un appuntamento ricorrente fino a fine anno, vuoi continuare?'}
        alertDescription={
          <>
            <Typography>DATA INIZIO: {toDateShort(selectedDate)}</Typography>
            <Typography>ORARIO: {toHourMinute(selectedHour.start)}-{toHourMinute(selectedHour.end)}</Typography>
            <Typography>EVENTO: {event?.name}</Typography>
          </>
        }
      />
      <Form {...form}>
        <form onSubmit={
          form.handleSubmit((data) => onSubmit())
        }>
          <div className="flex flex-col gap-8 max-w-sm">
            <Progress value={(currentBookingPhase + 1) * 25}/>
            <div className='flex gap-6 items-center'>
              {currentBookingPhase !== BOOKING_PHASE.EVENT_INFO && (
                <Button type='button' variant='outline' size='sm' onClick={() => navigateBack()}>
                  <ChevronLeftIcon/>
                </Button>
              )}
              <Typography variant='h3' as='h1'>
                {t('bookingPage.title')}
              </Typography>
            </div>
            <div>
              {(() => {
                switch (currentBookingPhase) {
                  case BOOKING_PHASE.EVENT_INFO:
                    return (
                      <Event
                        events={events}
                        form={form}
                        navigationActions={navigationActions}
                      />
                    );
                  case BOOKING_PHASE.CALENDAR:
                    return (
                      <Calendar
                        form={form}
                        navigationActions={navigationActions}
                      />
                    );
                  case BOOKING_PHASE.AVAILABILITY:
                    return (
                      <Availabilities
                        navigationActions={navigationActions}
                        form={form}
                      />
                    );

                  case BOOKING_PHASE.CONFIRM:
                    return (
                      <FinalizeBooking
                        events={events}
                        form={form}
                      />
                    );

                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </form>
      </Form>
      <ConfirmAlert
        onChange={tooggleVisibilitySuccessAlert}
        open={openSuccessAlert}
        onConfirm={async () => {
          paymentType === 'withCash' ? navigate((user && userIsAdminOrTrainer(user.role_id)) ? '/admin/bookings' : routes.USER) : navigate(routes.PAYMENT)
        }}
        alertTitle={'Apputamento prenotato con successo!'}
        confirmButtonLabel={paymentType === 'withCash' ? 'Vedi i tuoi appuntamenti' : 'Paga'}
        alertDescription={
          <RadioGroup className='mt-2' value={paymentType} onValueChange={(value) => setPaymentType(value)} defaultValue="withCard">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value='withCard' id="withCard"/>
              <Label htmlFor="withCard">Paga online con carta</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="withCash" id="withCash"/>
              <Label htmlFor="withCash">Paga in clinica</Label>
            </div>
          </RadioGroup>
        }
      />
    </>
  );
}

export default BookingPage;