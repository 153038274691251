import React, { Dispatch, SetStateAction } from 'react';
import {FormType, NavigationActionsType} from '../BookingPage';
import i18n from 'src/i18n';
import { Button } from 'src/shadcn/components/ui/Button';
import {toHourMinute} from "../../../shared/helpers/utils";
import {TimeRangeType} from "../../../shared/types/types";

type Props = {
  hour: TimeRangeType;
  id: number;
  setIsClicked: Dispatch<SetStateAction<{ id: number; isOpen: boolean }>>;
  isClicked: { id: number; isOpen: boolean };
  navigationActions: NavigationActionsType;
} & FormType;

function Hour({ hour, id, isClicked, setIsClicked, navigationActions, form }: Props) {
  return (
    <div className="w-full">
      {isClicked.isOpen && isClicked.id === id ? (
        <div className={`flex`}>
          <div
            className={`flex-grow flex justify-center items-center border-secondary bg-secondary mr-1 p-2`}
          >
            {`${toHourMinute(hour.start)}`}
          </div>
          <Button
            onClick={() => {
              form.setValue('selectedHour', { start: hour.start, end: hour.end });
                navigationActions.navigateForward();
            }}
            className={`ml-1 p-2`}
          >
            {i18n.t('hour.confirm')}
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => {
            setIsClicked({ id: id, isOpen: true });
          }}
          variant="outline"
          className="p-2 border-primary"
        >
          {`${toHourMinute(hour.start)} - ${
            toHourMinute(hour.end)
          }`}
        </Button>
      )}
    </div>
  );
}

export default Hour;
