import React, {useState} from 'react';
import {Button} from 'src/shadcn/components/ui/Button';
import AuthApi from './AuthApi/LoginService';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Routes from '../../routes';
import {GRID_ONE_COL} from '../../shared/constants/constant';
import i18n from '../../i18n';
import LoadingService from '../../components/loading/LoadingService';
import SessionService from '../../services/SessionService';
import {Label} from 'src/shadcn/components/ui/Label';
import ToastService from '../../services/ToastService';
import {Input} from 'src/shadcn/components/ui/Input';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/shadcn/components/ui/Form";
import {Typography} from 'src/shadcn/components/ui/Typography';

export const SendEmailForResetPassord = () => {
  const [email, setEmail] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const getResetPasswordEmail = async () => {
    try {
      await AuthApi.get_reset_password_email(email);
      setDisableSubmit(true);
      ToastService.success(i18n.t('forgotPassword.resetInstructionEmailSent'));
    } catch (e) {
      ToastService.error(e);
    }
  };

  return (
    <>
      <div className="grid col-1 gap-4">
        <Typography variant='h3' as='h1'>{i18n.t('forgotPassword.title')}</Typography>
        <div>
          <Label
            className="block text-sm font-bold mb-2"
            htmlFor="email"
          >
            {i18n.t('form.email')}
          </Label>
          <Input
            id="email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Button
            disabled={disableSubmit}
            onClick={getResetPasswordEmail}
          >{i18n.t('forgotPassword.buttonReset')}</Button>
        </div>
      </div>
    </>
  );
};

type InitialFormType = {
  email: string;
  password: string;
};

let schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .test(function (value) {
      return true;
    }),
}).required();

const LoginPage = () => {
  const form = useForm<InitialFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    }
  });
  const {register, handleSubmit, formState, control} = form;
  const navigate = useNavigate();

  const myFunc = async (value: InitialFormType) => {
    try {
      LoadingService.show();
      SessionService.logOut();
      const user = await AuthApi.login({
        email: value.email,
        password: value.password,
      });
      navigate(Routes.BOOKING);
      SessionService.login(user);
    } catch (e: any) {
      ToastService.error(e);
    } finally {
      LoadingService.hide();
    }
  };

  return (
    <Form {...form}>
      <form className={GRID_ONE_COL} onSubmit={handleSubmit(myFunc)}>
        <Typography variant='h3' as='h1'>{i18n.t('login.title')}</Typography>
        <FormField
          control={control}
          name={`email`}
          render={({field}) => (
            <FormItem>
              <FormControl>
                <Input
                  autoComplete="email"
                  placeholder={'Email'}
                  {...field}
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={`password`}
          render={({field}) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder={'Password'}
                  {...field}
                />
              </FormControl>
              <FormMessage/>
            </FormItem>
          )}
        />
        <div>
          <Button
            type={'submit'}
          >Accedi</Button>
        </div>
        <div>
          <Button
            type={'button'}
            variant='secondary'
            onClick={() => navigate(Routes.SIGNUP)}
          >{i18n.t('signUp.mainButton')}</Button>
        </div>
        {/*FILO change it to a lin*/}
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(Routes.LOGIN_FORGOT_PASSWORD);
          }}
        >
          {i18n.t('login.passwordLost')}
        </div>
      </form>
    </Form>
  );
};

export default LoginPage;
