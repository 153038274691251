import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'src/shadcn/components/ui/Typography';
import i18n from '../i18n';
import routes from '../routes';
import { FaInstagram } from 'react-icons/fa';
import { CiWavePulse1 } from "react-icons/ci";

const Footer = () => {
  return (
    <div className="grid grid-cols-4 justify-items-center items-center w-screen h-10 gap-2 bg-secondary">
      <Link to={routes.CONTACT}>
        <span>{i18n.t('footer.contact')}</span>
      </Link>
      <Link to={routes.PRIVACY_POLICY}>
        <Typography>Privacy policy</Typography>
      </Link>
      <Link
        to='https://www.instagram.com/bulgheronimatteo/?hl=en'
        target="_blank"
      >
        <FaInstagram/>
      </Link>
        <a className='flex items-center cursor-pointer' href={"https://www.filippogallizia.com"} target="_blank"
           rel="noopener noreferrer">
          <span className='mr-1'>made btw</span> <CiWavePulse1/> <span className='ml-2'>by Filips</span>
        </a>
    </div>
  );
};

export default Footer;
