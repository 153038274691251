import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { AiFillCopy } from 'react-icons/ai';
import ToastService from 'src/services/ToastService';
import { Typography } from 'src/shadcn/components/ui/Typography';

const ContactPage = () => {
  return (
    <div className="flex flex-col gap-8 justify-center">
      <Typography variant='h3' as='h1'>
        {i18n.t('contact.title')}
      </Typography>
      <div className={`grid grid-cols-1 gap-8 justify-items-start`}>
        <div className="flex gap-8 items-center justify-between">
          <Typography>
            <span className={`mr-2`}>Email:</span>{' '}
            {i18n.t('contact.body.email')}
          </Typography>
          <div
            className="cursor-pointer"
            onClick={async () => {
              await navigator.clipboard.writeText(i18n.t('contact.body.email'));
              ToastService.success('email copiata');
            }}
          >
            <AiFillCopy />
          </div>
        </div>
        <div className="flex gap-2">
          <Typography>{i18n.t('contact.body.address')}</Typography>
          <Link
            to={{
              pathname:
                'https://www.google.com/maps/place/Via+Osti,+20122+Milano+MI,+Italy/@45.4589123,9.189785,17z/data=!3m1!4b1!4m5!3m4!1s0x4786c6a83f082c6b:0xc2864de44532ba97!8m2!3d45.4589123!4d9.1919737',
            }}
            target="_blank"
          >
            <Typography>via osti - Milano</Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
