class Routes {
  public ROOT = '/';
  public LOGIN = '/login';
  public LOGIN_FORGOT_PASSWORD = '/sendTokenPassword';
  public SIGNUP = '/signup';
  public CONTACT = '/contact';
  public PRIVACY_POLICY = '/privacyPolicy';
  public RESET_PASSWORD = '/newPassword';
  public USER = '/user';
  public HOME = '/home';
  public BOOKING = '/booking';
  public PAYMENT = '/payment';
  public PAYMENT_SUCCESS = '/payment/success';
  public PAY_IN_CLINIC = '/payment/payInClinic';
  // ADMIN
  public ADMIN = 'admin';
  public ADMIN_RESOURCE_LIST(resource: string) {
    return `${resource}`;
  }
  public ADMIN_RESOURCE_VIEW(resource: string, id: string | number = ':id') {
    return `/admin/${resource}/${id}`;
  }
  public ADMIN_RESOURCE_NEW(resource: string) {
    return `/admin/${resource}/new`;
  }
  public ERRORS_AND_WARNINGS = '/errors';
}

export default new Routes();
